import React, { Component } from "react";
import Navbar from "./Navbar";
import Sidebar from "./Sidebar";
export class AdminLayout extends Component {
  constructor(props) {
    super(props);
    this.state = { menu: false };
  }
  toggleMobileMenu = () => {
    this.setState({ menu: !this.state.menu });
  };
  render() {
    return (
      <div
        className={`AdminLayout main-wrapper ${
          this.state.menu ? "slide-nav" : ""
        }`}
      >
        <div className="global_loader_container d-none" id="globalLoader">
          <div className="spinner"></div>
        </div>
        <div className="navbar-conent">
          <Navbar onMenuClick={(value) => this.toggleMobileMenu()} />
        </div>
        <div className="main-wrapper">
          <div className="sidebar-content">
            <Sidebar />
          </div>
          <div className="page-content page-wrapper">{this.props.children}</div>
        </div>
      </div>
    );
  }
}

export default AdminLayout;
