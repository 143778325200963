/**
 * Signin Firebase
 */

import React, { Component } from "react";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { emailrgx } from "../../constants";

const schema = yup.object({
  email_address: yup
    .string()
    .matches(emailrgx, "Email is required")
    .required("Email is required")
    .trim(),
});

const ForgotPassword = (props) => {
  const {
    handleSubmit,
    control,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const onSubmit = (data) => {
    props.api.apiResetPassword(data).then(() => {
      alert(
        "Your password reset is successfull please check you inbox for your new password"
      );
      navigate("/login");
    });
  };

  return (
    <AppUngardWrapper title="ForgotPassword">
      <div className="account-box">
        <div className="account-wrapper">
          <div className="account-logo">
            <Link to="/app/main/dashboard">
              <img src={imagepaths.headerlogo} alt="headerlogo" />
            </Link>
          </div>
          <h3 className="account-title">Forgot Password?</h3>
          <p className="account-subtitle">
            Enter your Email to get a password reset link
          </p>
          {/* Account Form */}
          <form onSubmit={handleSubmit(onSubmit)} className="mt-5">
            <AppYupInput
              name={"email_address"}
              control={control}
              errors={errors}
              label="Email ID"
              defaultValue="Emailid@gmail.com"
            />
            <div className="form-group text-center">
              <button className="btn btn-primary account-btn" type="submit">
                Reset Password
              </button>
            </div>
          </form>
        </div>
      </div>
      <div className="account-content d-none">
        <div className="container">
          <div className="account-box">
            <div className="account-wrapper">
              <i className="fa fa-check-circle circle_icon"></i>
              <p className="text-center center_box">
                Password Reset Successfully. Please check your email and login
                with the temporary password.
              </p>
              <div className="form-group text-center">
                <button
                  className="btn btn-primary account-btn max-wd-120 mx-auto"
                  type="submit"
                >
                  <Link className="text-white" to="/login">
                    Close
                  </Link>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </AppUngardWrapper>
  );
};

export default connect(ForgotPassword);
