/* global imagepaths */
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { alphaNumericPattern, emailrgx } from "../../constants";

const schema = yup.object({
  email_address: yup
    .string()
    .matches(emailrgx, "Email is required")
    .required("Email is required")
    .trim(),
  password: yup.string().min(8).max(8).required("Password is required").trim(),
});

const ResetPassword = (props) => {
  const [oldeye, setOldeye] = useState(true);
  const [neweye, setNeweye] = useState(true);
  const [confirmeye, setConfirmeye] = useState(true);
  const {
    handleSubmit,
    control,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = (data) => {
    console.log("data", data);
    // props.api.apiLogin(data).then(()=>{
    // });
    navigate("/admin/dashboard");
  };

  const onOldEyeClick = () => {
    setOldeye(!oldeye);
  };

  const onNewEyeClick = () => {
    setNeweye(!neweye);
  };

  const onConfirmEyeClick = () => {
    setConfirmeye(!confirmeye);
  };

  const oldEyeAction = () => (
    <span
      onClick={onOldEyeClick}
      className={`fa toggle-password" ${oldeye ? "fa-lock" : "fa-eye"}`}
    />
  );

  const newEyeAction = () => (
    <span
      onClick={onNewEyeClick}
      className={`fa toggle-password" ${neweye ? "fa-eye-slash" : "fa-eye"}`}
    />
  );
  return (
    <AppUngardWrapper title="Signup">
      <div className="account-box">
        <div className="account-wrapper">
          <div className="account-logo">
            <Link to="/login">
              <img src={imagepaths.headerlogo} alt="headerlogo" />
            </Link>
          </div>
          <h3 className="account-title">Reset Password </h3>
          <p className="account-subtitle">Enter your new password</p>
          <form onSubmit={handleSubmit(onSubmit)} className="mt-5">
            <AppYupInput
              name={"email_address"}
              control={control}
              errors={errors}
              label="Email ID"
              defaultValue="Emailid@gmail.com"
            />
            <AppYupInput
              name={"old_password"}
              type={oldeye ? "password" : "text"}
              control={control}
              errors={errors}
              label="Old Password"
              defaultValue="aaBB11##"
              slots={{ right: oldEyeAction }}
              className="mb-3"
            />
            <AppYupInput
              name={"new_password"}
              type={neweye ? "password" : "text"}
              control={control}
              errors={errors}
              label="New Password"
              defaultValue="aaBB11##"
              slots={{ right: newEyeAction }}
              className="mb-3"
            />
            <div className="form-group text-center mb-3">
              <button className="btn btn-primary account-btn" type="submit">
                Reset
              </button>
            </div>
          </form>
        </div>
      </div>
    </AppUngardWrapper>
  );
};

export default connect(ResetPassword);
