import React, { Component } from 'react';
import {
    DeleteOutlined,
    DownloadOutlined,
    UploadOutlined,
  } from "@ant-design/icons";
import { Dropdown } from "antd";

export class AppUpload extends Component {
    constructor(props) {
        super(props);
        this.state = {
            attrs: this.attrs,
            fileUpload: false,
            uploadedFile: [],
            statusValue: "APPROVED",
        };
        this.items = [
            {
                label: "Download",
                key: "download",
                icon: <DownloadOutlined />,
            },
            {
                label: "Delete",
                key: "delete",
                icon: <DeleteOutlined />,
            },
        ];
        this.menuProps = {
            items: this.items,
            onClick: (e) => this.handleMenuClick(e),
        };
    }

    get attrs() {
        return Object.except(this.props, [
          "className",
          "style",
          "labelClass",
          "controlClass",
          "labelpos",
          "children",
          "type",
          `dispatch`,
          `setstore`,
          `initialconstruct`,
          `navigate`,
        ]);
      }

    handleMenuClick(e) {
        if (e.key === "delete") {
            this.deleteUploadFile();
        }
        if (e.key === "download") {
            this.downloadFile();
        }
    }
    deleteUploadFile() {
        let targetInputFile = document.querySelector(
            `[data-uploadfilecontainer='${this.state.attrs.name}'] input`
        );
        let uploadBtn = document.querySelector(
            `[data-uploadfilecontainer='${this.state.attrs.name}'] > button > span.labelname`
        );
        targetInputFile.value = "";
        uploadBtn.innerText = this.state.attrs.labelname || "Choose File";
        this.setState({ fileUpload: false });
    }

    downloadFile() {
        let file, fr;
        file = this.state.uploadedFile;
        fr = new FileReader();
        fr.readAsDataURL(file);

        let blob = new Blob([file], { type: "application/pdf" });
        let objectURL = window.URL.createObjectURL(blob);

        if (navigator.appVersion.toString().indexOf(".NET") > 0) {
            window.navigator.msSaveOrOpenBlob(blob, "myFileName.pdf");
        } else {
            let link = document.createElement("a");
            link.href = objectURL;
            link.download = this.state.uploadedFile.name;
            document.body.appendChild(link);
            link.click();
            link.remove();
        }
    }

    fileOnchange(e, attrs) {
        this.setState({
            uploadedFile: e.target.files[0],
        });
        let fileName = e.target.files[0].name.replace(/(\.\w+)+$/, "");
        let uploadBtn = document.querySelector(
            `[data-uploadfilecontainer='${attrs.name}'] > button > span.labelname`
        );
        uploadBtn.innerText = fileName;
        this.setState({ fileUpload: true });
    }

    updateStatusValue = (value, record, name) => {
        console.log(value);
        this.setState({ statusValue: value });
    };
    render() {
        let { props, state } = this;
        let { attrs } = state;
        let { iconClasses, status } = enums;
        return (
            <div className="file d-flex align-items-center">
                <div
                    className="file file_input_outer"
                    data-uploadfilecontainer={attrs.name}
                >
                    <button className={`file_overlay_btn`}>
                        <span className="labelname">
                            {attrs.labelname || "Choose File"}
                        </span>
                        {attrs.editaccess === "true" && (
                            <i className="fa fa-upload ms-1"></i>
                        )}
                        {attrs.editaccess === "false" && (
                            <i className="fa fa-download ms-1"></i>
                        )}
                    </button>
                    <label htmlFor={attrs.id} className={"cursor-pointer"}>
                        <input
                            className={"" + (props?.controlClass || "")}
                            type={'file'}
                            accept="application/pdf"
                            onChange={(e) => this.fileOnchange(e, attrs)}
                            {...attrs}
                        />
                        {props.children}
                    </label>
                </div>
                {attrs.editaccess === "true" && (
                    <>
                        <div
                            className={`${this.state.fileUpload
                                ? "upload_stats_icon ms-2 success"
                                : "upload_stats_icon ms-2"
                                }`}
                        ></div>
                        <Dropdown menu={this.menuProps} placement="bottomRight">
                            <a className="ellipsis_dropdown_btn">
                                <i className="fa fa-ellipsis-v"></i>
                            </a>
                        </Dropdown>
                    </>
                )}
                {attrs.editaccess === "false" && (
                    <>
                        <div className="mx-3"></div>
                        <div className="dropdown action-label text-center custom_status_dropdown">
                            <a
                                className="btn btn-white btn-sm btn-rounded dropdown-toggle"
                                href="#"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                            >
                                <i
                                    className={Object.className({
                                        "fa fa-dot-circle-o": true,
                                        [iconClasses[this.state.statusValue]]: true,
                                    })}
                                />
                                {this.state.statusValue.toLowerCase().toTitleCase()}
                            </a>
                            <div className="dropdown-menu dropdown-menu-right">
                                {status.map((value) => (
                                    <div
                                        key={value}
                                        className="dropdown-item"
                                        onClick={() => this.updateStatusValue(value)}
                                    >
                                        <i
                                            className={"fa fa-dot-circle-o " + iconClasses[value]}
                                        />
                                        {value.toLowerCase().toTitleCase()}
                                    </div>
                                ))}
                            </div>
                        </div>
                    </>
                )}
            </div>
        )
    }
}

export default AppUpload