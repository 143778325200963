import React, { useEffect, useRef } from "react";

const AppContentModal = (props) => {
    let {
        // message = "Alert Message",
        className = "",
        closeIcon = "fa fa-close",
        // contentClass = "",
        onClose = () => "",
        // alert = "success",
        title = "Alert Title",
    } = props;
    let attrs = Object.except(props, ["title"]);
    const closebtn = useRef();
    useEffect(() => {
        const { onLoad = () => "" } = props;
        onLoad({ closebtn });
    }, []);
    return (
        <>
            <div
                className={"modal custom-modal fade " + className}
                role="dialog"
                {...attrs}
            >
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content alert_modal_content">
                        <div className="modal-header">
                            <h2 className="fs-4">{title}</h2>
                            <button
                                type="button"
                                ref={closebtn}
                                className="close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                                onClick={onClose}
                            >
                                <span aria-hidden="true">
                                    <i className={closeIcon}></i>
                                </span>
                            </button>
                        </div>
                        <div className="modal-body py-0">
                            {props.children}
                            {props.footer && (
                                <div className="form-group text-center">
                                    <button
                                        type="button"
                                        ref={closebtn}
                                        className="btn btn-primary btn-rounded text-dark account-btn max-wd-120 mx-auto modal_close_grey_btn me-3"
                                        data-bs-dismiss="modal"
                                        aria-label="Close"
                                        onClick={onClose}
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        type="submit"
                                        className="btn btn-primary btn-rounded text-dark account-btn max-wd-120 mx-auto"
                                    >
                                        Submit
                                    </button>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default AppContentModal;