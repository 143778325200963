import { Helmet } from "react-helmet";

export class AppUngardWrapper extends Component {
  render() {
    let { props } = this;
    let { title = "", slots = {} } = props;
    return (
      <>
        <Helmet>
          <title>{title} - Biryani City</title>
          <meta name="description" content="" />
        </Helmet>
        <div className="account-content">
          <div className="container">{props.children}</div>
        </div>
      </>
    );
  }
}

export default AppUngardWrapper;
