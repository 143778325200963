/* global imagepaths connect React Component */
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

export const BreadCum = ({
  customTitle,
  title,
  backlink,
  back,
  hidebreadcrum,
}) => (
  <div className="col">
    <div className="d-flex align-items-center">
      {back && (
        <button className="custom_back_btn">
          {/* <i className="fa fa-angle-double-left"></i> */}
        </button>
      )}
      <h3 className="page-title m-0">{customTitle || title}</h3>
    </div>
    {hidebreadcrum ? (
      <></>
    ) : (
      <ul className="breadcrumb">
        {back && (
          <li className="breadcrumb-item">
            <Link to={backlink}>{back}</Link>
          </li>
        )}
        <li className="breadcrumb-item active">Dashboard / {title}</li>
      </ul>
    )}
  </div>
);

export const IconBreadcrumb = ({ customTitle, title, backlink, back }) => (
  <div className="col">
    <div className="d-flex align-items-center">
      {back && (
        <Link to={backlink} className="custom_back_btn">
          <i className="fa fa-arrow-left"></i>
        </Link>
      )}
      <h3 className="page-title m-0">{customTitle || title}</h3>
    </div>
    {/* <ul className="breadcrumb">
      {back && (
        <li className="breadcrumb-item">
          <Link to={backlink}>{back}</Link>
        </li>
      )}
      <li className="breadcrumb-item active">Dashboard / {title}</li>
    </ul> */}
  </div>
);

export class AppWrapper extends Component {
  render() {
    let { props } = this;
    let {
      title = "",
      back = "Dashboard",
      className = "",
      backlink,
      customTitle = "",
      slots = {},
      isIconbreadcrum = false,
      hidebreadcrum = false,
    } = props;
    return (
      <div className={"content container-fluid relative" + className}>
        <Helmet>
          <title>{title} - Biryani City</title>
          <meta name="description" content="Login page" />
        </Helmet>
        <div className="page-header">
          <div className="row align-items-center">
            {isIconbreadcrum ? (
              <IconBreadcrumb
                title={title}
                back={back}
                backlink={backlink}
                customTitle={customTitle}
              />
            ) : (
              <BreadCum
                title={title}
                // back={back}
                backlink={backlink}
                customTitle={customTitle}
                hidebreadcrum={hidebreadcrum}
              />
            )}
            {slots.breadCumRight && <slots.breadCumRight {...props} />}
          </div>
        </div>
        {props.children}
      </div>
    );
  }
}

export default connect(AppWrapper);
