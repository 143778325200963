import methods from "./methods";
import apis from "./apis";
import mokeresults from "./mokeresults";
import _enum from "./enums/global.enums";
window._enum = _enum;
window.mokeresults = mokeresults;

const setInitials = () => {
    window._enum = {
        ..._enum,
        ...window.getpathkey(_enum)
    };
    window.mokeresults = {
        ...mokeresults,
        ...window.getpathkey(mokeresults)
    }
    console.log({ setInitials: window._enum });
};
export const mixins = {
    ...methods,
    api: apis,
    setInitials,
    _enum: () => (window._enum),
}

export default mixins;