import { configureStore } from "@reduxjs/toolkit";
import initialState from "../state";
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
import thunk from 'redux-thunk';
import rootReducers from '../slices'

const persistConfig = {
    key: "root",
    version: 1,
    storage,
    whiteList: ["root"]
};

export const persistedReducer = persistReducer(persistConfig, rootReducers);

export const store = configureStore({
    reducer: persistedReducer,
    preloadedState: { root: initialState },
    middleware: [thunk],
});

window.store = store;

export const persistor = persistStore(store)
