import * as enums from "../assets/json/enums";
import menucategories from "../assets/json/menu_categories";
window.menucategories = menucategories;
window.enums = enums;
export const state = {
    config: {
        name: "Biryani City",
        shortname: "BiryaniCity",
        tagline: "",
        logo: "self",
        colors: {
            "defaultbg": "white",
            "defaultText": "black",
            "primary": "#1c67f6",
            "primaryText": "white",
            "secoundary": "#002F75",
            "secoundaryText": "white",
        }
    },
    loading: false,
    requests: [],
    theme: "",
    auth: "",
    user: null,
}

export default state;