export default {
    apiLogout(data = {}, urldata = {}) {
        const redirect = () => {
            app.props.setstore("auth", false);
            app.props.setstore("user", false);
            localStorage.clear();
            navigate("/login");
        };
        if (data.skipApi) { return redirect(); }
        return redirect();
        // return this.request(this.api().logout, data)
        //     .urltransform({
        //         ...urldata,
        //         ...mixins.params()
        //     })
        //     .exec().log().mapresults((responce) => {
        //         redirect();
        //     }).get();
    },
    apiFetchUser(data, urldata = {}) {
        return this.request(this.api().me, data)
            .urltransform({
                ...urldata,
                ...mixins.params()
            })
            .exec().log().mapresults((responce) => {
                let data = responce.data;
                if (!data) { return responce; }
                console.log("data from fetch user", data);
                app.props.setstore("user", data);
            }).get();
    },
    apiLogin(data, urldata = {}) {
        return this.request(this.api().login, data)
            .urltransform({
                ...urldata,
                ...mixins.params()
            })
            .exec().log().mapresults(async (responce) => {
                let data = responce.data;
                if (!data) { return responce; }
                app.props.setstore("authObj", data);
                app.props.setstore("auth", data.access_token);
                await this.apiFetchUser();
                return responce;
            }).get();
    },
    apiResetPassword(data, urldata = {}) {
        return this.request(this.api().reset, data)
            .urltransform({
                ...urldata,
                ...mixins.params()
            })
            .exec().log().get();
    },
    apiRefreshToken(data = {}, urldata = {}) {
        let refreshTokenAttemp = localStorage.getItem("refreshTokenAttemp");
        refreshTokenAttemp = refreshTokenAttemp || 1;
        localStorage.setItem("refreshTokenAttemp", +refreshTokenAttemp + 1);

        return this.request(this.api().refresh, data, {
            refresh: app.props.store.authObj.refresh_token
        }).urltransform({
            ...urldata,
            ...mixins.params()
        }).exec().log()
            .mapresults(async (responce) => {
                let data = responce.data;
                app.props.setstore("authObj", data);
                app.props.setstore("auth", data.access_token);
                app?.props?.setstore("logged", { ...data });
                app?.props?.setstore("loggedIn", new Date().getTime());
                localStorage.setItem("refreshTokenAttemp", 0);
            }).get();
    }
}