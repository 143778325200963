
/* global imagepaths connect React Component */
import { Suspense } from "react";
import { useNavigate } from "react-router-dom";
import './App.scss';
import AppLayout from "./layout/AppLayout";

const checkLogin = function(props) {
  return true;
}

const Navigate = (props) => {
  window.navigate = useNavigate();
  checkLogin(props);
  return props.children;
};


export class App extends Component {
  constructor(props) {
		super(props);
		props.initialconstruct.bind(this)("app");
		this.store([
			{name:'requests',value:[]},
		]);
	}
  render() {
    return (
      <Navigate>
        <Suspense>
          <AppLayout>
            {this.props.children}
          </AppLayout>
        </Suspense>
      </Navigate>
    )
  }
}

export default connect(App);
