import moment from "moment";
import { connect } from "react-redux";


const mixin = function () {
    return window.mixins;
}
const methods = {
    params() {
        return window.location.href.url().params;
    },
    isEmpty(val) {
        let empty = [NaN, undefined, null, ''].includes(val);
        let isObject = typeof val == "object";
        let isArray = isObject && val instanceof Array;
        let isEmptyArray = !empty && isArray && val.length == 0;
        let isEmptyObject = !empty && isObject && Object.entries(val).length == 0;
        return (empty || isEmptyArray || isEmptyObject);
    },
    empty(data, Component) {
        return !mixins.isEmpty(data) && <Component />
    },
    checkParent(ele, callback = (() => (false))) {
        let temp = ele;
        while (temp) {
            let flag = callback(temp, temp.parentElement);
            if (flag) {
                return temp.parentElement || flag;
            }
            temp = temp.parentElement;
        }
        return temp;
    },
    connect(component) {
        return connect(
            (state) => ({ store: state.root }),
            (dispatch) => {
                let ret = {};
                ret.dispatch = (payload) => dispatch.bind(ret)({ type: 'root/store', payload: payload });
                ret.watch = {};
                ret.setstore = (name, value) => (name instanceof Array) ? ret.dispatch(name) : ret.dispatch({ name, value });
                ret.mixin = mixin();
                ret.location = window.location;
                ret.urlparams = ret.location.href.url().params;
                ret.api = ret.mixin.api;
                ret.apis = () => ret.api.api();
                ret._enum = ret.mixin._enum();
                ret.emit = ret.mixin.emit;
                ret.api = Object.map(ret.api, (v) => v.bind(Object.assign({}, { props: ret }, ret.api)));
                ret.initialconstruct = ret.mixin.initialconstruct;
                ret.navigate = window.navigate;
                return ret;
            }
        )(component);
    },
    initialconstruct(key, props = null) {
        props = Object.assign({}, props || this.props);
        this.store = props.setstore;
        this.dispatch = props.dispatch;
        this.navigate = props.navigate;
        this.mixin = props.mixin;
        this.emit = props.emit;
        this.api = this.mixin.api;
        this.apis = props.apis;
        this._enum = this.mixin._enum();
        this.api = Object.map(this.api, (v) => v.bind(Object.assign({}, { props }, this, this.api)));
        this.mixin = Object.map(Object.filter(this.mixin, (v) => typeof v === "function"), (v) => v.bind(this));
        this.watch = mixin().watch.bind(this);
        window[key] = this;
        window.$l = this.$l = props.$l;
    },
    getCurrentPath() {
        return window.reduxHistory ? window.reduxHistory.location.pathname : '';
    },
    getDotedKeyValue(key, obj) {
        let arr = key.split(".");
        if (arr.length > 1) {
            let tmp = obj, key = "", old = null;
            arr.forEach(k => {
                old = tmp; tmp = tmp[k]; key = k;
            });
            return old[key];
        } else {
            return obj[key]
        }
    },
    getDotedValueKeys(key, obj) {
        let arr = key.split(".");
        if (arr.length > 1) {
            let tmp = obj || {}, key = "", old = null;
            arr.forEach(k => {
                old = tmp; tmp = tmp[k]; key = k;
            });
            return {
                obj: old,
                key
            };
        } else {
            return {
                obj,
                key
            }
        }
    },
    setStates(key, val) {
        let self = this;
        return new Promise((res, rej) => {
            self.setState((...arg) => {
                let ret = mixin().getDotedValueKeys(key, arg[0]);
                ret.obj[ret.key] = (typeof val === "function") ? val(...arg) : val;
                return {
                    ...arg[0]
                };
            }, (...arg) => {
                res(...arg);
            });
        });
    },
    watch(watchers, nextState = null, prevState = null) {
        prevState = prevState || { ...this.props };
        let keys = Object.keys(watchers);
        keys.map((key) => {
            let oldval = mixin().getDotedKeyValue(key, prevState);
            let newval = mixin().getDotedKeyValue(key, nextState);
            if (mixin().detectComplexChange(oldval, newval)) {
                let func = watchers[key];
                if (func instanceof Function) {
                    func.bind(this)(newval, oldval);
                }
            }
            return key;
        })
    },
    detectComplexChange(prev, next) {
        let trusy = prev || next;
        if (((typeof prev) !== (typeof next)) || prev === null || next === null) {
            return prev !== next;
        } else if (["object"].includes(typeof trusy)) {
            if (prev === next) { return false; }
            let nextLen = (trusy instanceof Array) ? next.length : Object.entries(next).length;
            let prevLen = (trusy instanceof Array) ? prev.length : Object.entries(prev).length;
            if (prevLen !== nextLen) { return true; }
            else { return JSON.stringify(prev) !== JSON.stringify(next) }
        } else {
            return prev !== next;
        }
    },
    getSelectionText() {
        let txt = '';
        if (window.getSelection) {
            txt = window.getSelection().toString();
        }
        else if (document.getSelection) {
            txt = document.getSelection().toString();
        }
        else if (document.selection) {
            txt = document.selection.createRange().text;
        }
        else return;
        return txt;
    },
    getDataNode(data) {
        let divEle = document.createElement("div");
        divEle.innerHTML = data;
        return divEle;
    },
    debounce(callback = (() => ('')), delay = 1000, name = "") {
        clearTimeout(window['gdbns' + name]);
        window['gdbns' + name] = setTimeout(() => {
            callback();
        }, delay);
    }
}

window.connect = methods.connect;
window.initialconstruct = methods.initialconstruct;
window.watch = methods.watch;
window.empty = methods.empty;

export default methods;