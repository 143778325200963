export class AppModalForm extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    let { props } = this;
    let { id, title, onClose, fieldclass, modalheadertext } = props;
    let attrs = Object.except(props, ["title", "type", "onClose"]);
    return (
      <AppModalBs
        id={id}
        title={title}
        contentClass={"max-w-3xl m-auto"}
        onClose={onClose}
      >
        {modalheadertext && (
          <p className="mandatory text-center">{modalheadertext}</p>
        )}

        <AppFormData fieldclass={fieldclass} {...attrs}></AppFormData>
      </AppModalBs>
    );
  }
}

export default AppModalForm;
