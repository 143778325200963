import React, { Component } from "react";
import MenulistNavbar from "../MenulistNavbar";
import mixinsFunc from "../mixins";
import FoodItemCard from "../FoodItemCard";

export class AddMenuItems extends Component {
  constructor(props) {
    super(props);
    props.initialconstruct.bind(this)("AddMenuItems");
    this.mixins = mixinsFunc(this);
    this.state = {
      activeButton: "nonveg",
      foodLists: [],
    };
  }
  get subcategory() {
    return this.props.urlparams.subcategory;
  }

  get location() {
    return this.props.urlparams.location;
  }
  componentDidMount() {
    this.fetchList();
  }

  fetchList(value) {
    let payload = {
      location: this.location.toTitleCase(),
    };
    this.props.api.apiGetMenuItems("", payload).then(({ data }) => {
      // console.log({ apiGetMenuItems: data });
      this.setState({
        foodLists: data,
      });
    });
  }

  onButtonClick(buttonName) {
    if (buttonName === "nonveg") {
      this.setState({
        ...this.state,
        activeButton: this.state.activeButton === buttonName ? "" : buttonName,
      });
    }

    if (buttonName === "veg") {
      this.setState({
        ...this.state,
        activeButton: this.state.activeButton === buttonName ? "" : buttonName,
      });
    }

    if (buttonName === "both") {
      this.setState({
        ...this.state,
        activeButton: this.state.activeButton === buttonName ? "" : buttonName,
      });
    }

    let filteredFood = this.state.foodLists?.filter(
      (item) => item.item_category === buttonName
    );
    console.log(filteredFood);

    if (filteredFood.length === 0) {
      this.setState({
        ...this.state,
        activeButton: this.state.activeButton === buttonName ? "" : buttonName,
      });
    } else {
      this.setState({
        ...this.state,
        foodLists: filteredFood,
        activeButton: this.state.activeButton === buttonName ? "" : buttonName,
      });
    }
  }

  handleAddItems(values) {
    console.log({ values });
  }

  getBackLink() {
    if (this.props.urlparams.location) {
      let backLink = `/client/menulist?location=${this.props.urlparams.location}&table_number=${this.props.urlparams.table_number}`;
      return backLink;
    } else {
      return "/client/menulist";
    }
  }
  render() {
    let { activeButton, foodLists } = this.state;
    // console.log(this.subcategory);
    let nextlink =
      `/client/foodcart?` +
      Object.QueryString({
        ...this.props.urlparams,
      });
    return (
      <>
        <MenulistNavbar back={this.getBackLink()} />
        <div className="menu_content">
          <div className="menu_title_box pb-0">
            <h2>All {this.subcategory?.spaceCase().toTitleCase()} Items</h2>
            <img src={imagepaths.seperatorIcon} alt="seperatorIcon" />
          </div>
          <div className="menu_filter_btn_container mb-0 py-0 d-none">
            <button
              type="button"
              className={`${activeButton === "nonveg" ? "active" : ""}`}
              onClick={() => this.onButtonClick("nonveg")}
            >
              Non-Veg
            </button>
            <button
              type="button"
              className={`${activeButton === "veg" ? "active" : ""}`}
              onClick={() => this.onButtonClick("veg")}
            >
              Veg
            </button>
            <button
              type="button"
              className={`${activeButton === "both" ? "active" : ""}`}
              onClick={() => this.onButtonClick("both")}
            >
              Both
            </button>
          </div>
          <div className="food_items_container menu_items_container">
            {foodLists
              ?.filter(
                (item) =>
                  (item?.item_sub_category.snakeCase() ||
                    item?.item_category.snakeCase()) === this.subcategory
              )
              .map((item, i) => {
                return (
                  <FoodItemCard
                    img={item?.img}
                    title={item.item_name}
                    foodCategory={item.item_category}
                    price={item.item_retail_price}
                    // handleAddItems={(...args) => this.handleAddItems(...args)}
                    description={item.item_description}
                    key={i}
                  />
                );
              })}
          </div>
        </div>
        <div className="bottom_cart_card">
          <div className="">
            <p>2 Item | $65</p>
            <p className="note">Extra charges may apply</p>
          </div>
          <button
            type="button"
            className="view_cart_btn"
            onClick={() => navigate(nextlink)}
          >
            View Cart
          </button>
        </div>
      </>
    );
  }
}

export default connect(AddMenuItems);
