import React, { Component } from "react";
let farr = [];
export class FoodItemCard extends Component {
  constructor(props) {
    super(props);
    props.initialconstruct.bind(this)("FoodItemCard");
    this.state = {
      inputvalue: 0,
      showAddBtn: true,
      cartItems: [],
    };
  }

  componentDidMount() {
    window.FoodItemCard = this;
  }

  handleAddItems(e, title) {
    console.log(this.state.inputvalue);
    this.plusQty(e, title);
    this.setState({
      showAddBtn: false,
    });
  }

  minusQty(e, title) {
    console.log(this.state.inputvalue);
    e.preventDefault();
    let value = this.state.inputvalue;
    if (value > 0) {
      value--;
    }
    if (value === 0) {
      this.setState({
        showAddBtn: true,
      });
    }
    this.setState({
      inputvalue: value,
    });
  }

  plusQty(e, title) {
    e.preventDefault();
    let value = this.state.inputvalue;
    value++;

    let item = {
      name: title,
      qty: value,
    };

    this.setState({
      inputvalue: value,
    });
    this.props.setstore("fooditem", {});
  }

  render() {
    let { img, title, foodCategory, price, description } = this.props;
    let { inputvalue, showAddBtn } = this.state;
    let isDecimal;
    if (price) {
      isDecimal = JSON.stringify(price).match(/\./g) ? true : false;
    }
    return (
      <>
        <div
          className={`card mb-3 food_item_card ${title
            ?.replace(/\s+/g, "_")
            .toLowerCase()}`}
        >
          <div className="row g-0">
            <div className="col-4 col-md-3 cart_btn_container">
              <img
                src={img ? img : imagepaths.foodplaceholdergreyimg}
                className="img-fluid w-100"
                alt=""
              />
              <div className="quantity">
                <a
                  href="#"
                  className="quantity__minus"
                  onClick={(e) => this.minusQty(e, title)}
                >
                  <span>-</span>
                </a>
                <input
                  name="quantity"
                  type="text"
                  className="quantity__input w-100"
                  value={inputvalue}
                  readOnly={true}
                />
                <a
                  href="#"
                  className="quantity__plus"
                  onClick={(e) => this.plusQty(e, title)}
                >
                  <span>+</span>
                </a>
              </div>
              {showAddBtn && (
                <button
                  type="button"
                  className="add_cart_btn"
                  onClick={(e) => this.handleAddItems(e, title)}
                >
                  Add +
                </button>
              )}
            </div>
            <div className="col-8 col-md-9">
              <div className="card-body">
                <div className="food_tag">
                  <span className="food_tag_title">Non-veg</span>
                  <span className="food_tag_icon"></span>
                </div>
                <h5 className="card-title">{title.toTitleCase()}</h5>
                <p className="card-text">{description}</p>
                <p className="price">
                  {isDecimal ? `$${price}` : `$${price}.00`}
                </p>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default connect(FoodItemCard);
