/* global imagepaths connect React Component */
import React, { useEffect, useState } from "react";
import { Navigation } from "react-minimal-side-navigation";
import "react-minimal-side-navigation/lib/ReactMinimalSideNavigation.css";
import { Link, useLocation } from "react-router-dom";
import adminMenu from "./Menulist/AdminMenu";
import locationManagerMenu from "./Menulist/LocationManagerMenu";

const Sidebar = (props) => {
  const location = useLocation();
  const [sidemenuData, setSidemenuData] = useState([]);
  let pathname = location.pathname;

  // useEffect(() => {
  //   console.log("Location changed", location.pathname);
  // }, [location]);

  useEffect(() => {
    const role = props?.store?.user?.role;
    console.log({ role });
    if (role === "ADMIN") {
      setSidemenuData(adminMenu);
    } else if (role === "LOCATION_MANAGER") {
      setSidemenuData(locationManagerMenu);
    }
  }, []);

  return (
    <div className="sidebar" id="sidebar">
      <div className="sidebar-inner slimscroll">
        <div id="sidebar-menu" className="sidebar-menu">
          <Navigation
            activeItemId={location.pathname}
            onSelect={({ itemId }) => {
              navigate(itemId);
            }}
            items={
              sidemenuData &&
              sidemenuData.map((obj, i) => {
                if (obj.subNav) {
                  return {
                    ...obj,
                    elemBefore: () => (
                      <img src={o?.iconStyle} className="menu-icon" />
                    ),
                    subNav: obj.subNav.map((o) => {
                      return {
                        ...o,
                        elemBefore: () => (
                          <img src={o?.iconStyle} className="menu-icon" />
                        ),
                      };
                    }),
                  };
                }
                return {
                  ...obj,
                  elemBefore: () => (
                    <div className="nav_menu_icon">
                      <img src={obj?.iconStyle} className="menu-icon" />
                      <img
                        src={obj?.iconActive}
                        className="menu-icon active_icon"
                      />
                    </div>
                  ),
                };
              })
            }
          />
        </div>
        <div className="bottom_action_box">
          <Link className="sidebar_logout_btn" to="/login">
            <span>
              <img src={imagepaths.logoutIcon} alt="" />
            </span>
            Logout
          </Link>
        </div>
      </div>
    </div>
  );
};

export default connect(Sidebar);
