import React, { Component } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

export class AppYupInput extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    let { props } = this;
    let {
      label,
      name,
      type = "text",
      control,
      errors,
      defaultValue,
      slots = {},
      className,
    } = props;
    return (
      <div className={"form-group " + className}>
        {label && <label>{label}</label>}
        <Controller
          name={name}
          control={control}
          render={({ field: { value, onChange } }) => (
            <div className="pass-group">
              <input
                className={Object.className({
                  "form-control": true,
                  "error-input": errors[name],
                })}
                type={type}
                value={value}
                onChange={onChange}
                autoComplete="false"
              />
              {slots.right && <slots.right />}
            </div>
          )}
          defaultValue={defaultValue}
        />
        <small>{errors && errors[name]?.message}</small>
      </div>
    );
  }
}

export default AppYupInput;
