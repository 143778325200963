import React, { Component } from 'react'
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import "./bootstrap";

import { RouterProvider } from "react-router-dom";
import mixins from "./mixins";
import router from "./routes";
import { persistor, store } from "./redux/store";
import { PersistGate } from "redux-persist/integration/react";
import { Provider } from "react-redux";
import Emitter from "./utils/emitters";
import "./components/partials";

window.mixins = mixins;
window.emit = new Emitter();

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
	<Provider store={store}>
		<PersistGate loading={null} persistor={persistor}>
			<RouterProvider router={router} />
		</PersistGate>
	</Provider>
);

reportWebVitals();
