
import { createSlice } from "@reduxjs/toolkit";
import reducers from "../reducers";
import initialState from "../state";

export const storeSlice = createSlice({
    name: "root",
    initialState,
    reducers: {
        store: (state, action) => {
            let payload = action.payload;
            let storeObject = state;
            let isset = (val, def = undefined) => {
                return val === undefined ? def : val;
            };
            let watch = {};
            let updateStore = (payload, storeObject) => {
                let key = payload.name;
                let watchme = watch[key] || ((v) => (v));
                let arr = payload.name.split(".");
                let value = typeof payload.value === "function" ? payload.value(state, action) : payload.value;
                if (arr.length > 1) {
                    let tmp = storeObject, key = "", old = null;
                    arr.forEach(k => {
                        old = tmp; tmp[k] = tmp[k] || {}; tmp = tmp[k]; key = k;
                    });
                    old[key] = isset(watchme(value), value);
                } else {
                    storeObject[key] = isset(watchme(value), value);
                }
                return storeObject;
            }
            if (typeof payload == "object" && payload instanceof Array) {
                payload.map(obj => {
                    storeObject = updateStore(obj, state);
                    return storeObject;
                });
            } else {
                storeObject = updateStore(payload, state);
            }
            return storeObject;
        },
        ...reducers
    },
    extraReducers: {

    }
});

window.action = storeSlice.actions;

export default storeSlice.reducer;