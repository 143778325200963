export default (node) => ({
    dineInOrderData: [
        {
            tnums: "77",
            email: "test@example.com",
            order_list: [
                {
                    dish: "Mutton Biryani",
                    price: 25.50,
                    quantity: "1"
                },
                {
                    dish: "Chicken Biryani",
                    price: 20.00,
                    quantity: "2"
                },
                {
                    dish: "Fish Biryani",
                    price: 34.50,
                    quantity: "1"
                },
                {
                    dish: "Paneer Biryani",
                    price: 20.00,
                    quantity: "1"
                },
            ],
            location: 'Allentown',
            bill_amt: "$25.00",
            status: 'accepted',
        },
        {
            tnums: "66",
            email: "test@example.com",
            order_list: [
                {
                    dish: "Butter Naan",
                    price: 100.00,
                    quantity: "4"
                },
                {
                    dish: "Chicken Tikka Masala",
                    price: 40.00,
                    quantity: "2"
                },
            ],
            location: 'Norristown',
            bill_amt: "$25.00",
            status: 'rejected',
        },
        {
            tnums: "77",
            email: "test@example.com",
            order_list: [
                {
                    dish: "Mutton Biryani",
                    price: 25.50,
                    quantity: "1"
                },
                {
                    dish: "Chicken Biryani",
                    price: 20.00,
                    quantity: "2"
                },
            ],
            location: 'Newark',
            bill_amt: "$25.00",
            status: 'new',
        },
        {
            tnums: "66",
            email: "test@example.com",
            order_list: [
                {
                    dish: "Mutton Biryani",
                    price: 25.50,
                    quantity: "1"
                },
                {
                    dish: "Chicken Biryani",
                    price: 20.00,
                    quantity: "2"
                },
            ],
            location: 'Allentown',
            bill_amt: "$25.00",
            status: 'paid',
        },
        {
            tnums: "66",
            email: "test@example.com",
            order_list: [
                {
                    dish: "Mutton Biryani",
                    price: 25.50,
                    quantity: "1"
                },
                {
                    dish: "Chicken Biryani",
                    price: 20.00,
                    quantity: "2"
                },
            ],
            location: 'Norristown',
            bill_amt: "$25.00",
            status: 'accepted',
        },
        {
            tnums: "77",
            email: "test@example.com",
            order_list: [
                {
                    dish: "Mutton Biryani",
                    price: 25.50,
                    quantity: "1"
                },
                {
                    dish: "Chicken Biryani",
                    price: 20.00,
                    quantity: "2"
                },
            ],
            location: 'Newark',
            bill_amt: "$25.00",
            status: 'accepted',
        },
        {
            tnums: "66",
            email: "test@example.com",
            order_list: [
                {
                    dish: "Mutton Biryani",
                    price: 25.50,
                    quantity: "1"
                },
                {
                    dish: "Chicken Biryani",
                    price: 20.00,
                    quantity: "2"
                },
            ],
            location: 'Allentown',
            bill_amt: "$25.00",
            status: 'accepted',
        },
        {
            tnums: "66",
            email: "test@example.com",
            order_list: [
                {
                    dish: "Mutton Biryani",
                    price: 25.50,
                    quantity: "1"
                },
                {
                    dish: "Chicken Biryani",
                    price: 20.00,
                    quantity: "2"
                },
            ],
            location: 'Norristown',
            bill_amt: "$25.00",
            status: 'accepted',
        },
        {
            tnums: "77",
            email: "test@example.com",
            order_list: [
                {
                    dish: "Mutton Biryani",
                    price: 25.50,
                    quantity: "1"
                },
                {
                    dish: "Chicken Biryani",
                    price: 20.00,
                    quantity: "2"
                },
            ],
            location: 'Newark',
            bill_amt: "$25.00",
            status: 'accepted',
        },
    ],
    banquetHallTableData: [
        {
            reservation_id: "0077766",
            name: "name",
            email: "test@example.com",
            event_type: "Birthday Party",
            date: "25/03/23",
            time: "08:00 a.m.",
            remarks: "Remarks here",
            status: "confirmed",
        },
        {
            reservation_id: "0077766",
            name: "name",
            email: "test@example.com",
            event_type: "Wedding Party",
            date: "25/03/23",
            time: "08:00 a.m.",
            remarks: "Remarks here",
            status: "reject",
        },
        {
            reservation_id: "0077766",
            name: "name",
            email: "test@example.com",
            event_type: "Corporate Events",
            date: "25/03/23",
            time: "08:00 a.m.",
            remarks: "Remarks here",
            status: "contacted",
        },
        {
            reservation_id: "0077766",
            name: "name",
            email: "test@example.com",
            event_type: "Birthday Party",
            date: "25/03/23",
            time: "08:00 a.m.",
            remarks: "Remarks here",
            status: "accept",
        },
        {
            reservation_id: "0077766",
            name: "name",
            email: "test@example.com",
            event_type: "Wedding Party",
            date: "25/03/23",
            time: "08:00 a.m.",
            remarks: "Remarks here",
            status: "new",
        },
        {
            reservation_id: "0077766",
            name: "name",
            email: "test@example.com",
            event_type: "Corporate Events",
            date: "25/03/23",
            time: "08:00 a.m.",
            remarks: "Remarks here",
            status: "accept",
        },
        {
            reservation_id: "0077766",
            name: "name",
            email: "test@example.com",
            event_type: "Birthday Party",
            date: "25/03/23",
            time: "08:00 a.m.",
            remarks: "Remarks here",
            status: "accept",
        },
        {
            reservation_id: "0077766",
            name: "name",
            email: "test@example.com",
            event_type: "Wedding Party",
            date: "25/03/23",
            time: "08:00 a.m.",
            remarks: "Remarks here",
            status: "accept",
        },
        {
            reservation_id: "0077766",
            name: "name",
            email: "test@example.com",
            event_type: "Corporate Events",
            date: "25/03/23",
            time: "08:00 a.m.",
            remarks: "Remarks here",
            status: "accept",
        },
    ],
    tableReservationsData: [
        {
            reservation_id: "0077766",
            name: "name",
            email: "test@example.com",
            no_of_persons: "2",
            location: "Allentown",
            date: "25/03/23",
            time: "08:00 a.m.",
            status: "accept",
        },
        {
            reservation_id: "0077766",
            name: "name",
            email: "test@example.com",
            no_of_persons: "2",
            location: "Norristown",
            date: "25/03/23",
            time: "08:00 a.m.",
            status: "reject",
        },
        {
            reservation_id: "0077766",
            name: "name",
            email: "test@example.com",
            no_of_persons: "2",
            location: "Newark",
            date: "25/03/23",
            time: "08:00 a.m.",
            status: "accept",
        },
        {
            reservation_id: "0077766",
            name: "name",
            email: "test@example.com",
            no_of_persons: "2",
            location: "Allentown",
            date: "25/03/23",
            time: "08:00 a.m.",
            status: "accept",
        },
        {
            reservation_id: "0077766",
            name: "name",
            email: "test@example.com",
            no_of_persons: "2",
            location: "Norristown",
            date: "25/03/23",
            time: "08:00 a.m.",
            status: "reject",
        },
        {
            reservation_id: "0077766",
            name: "name",
            email: "test@example.com",
            no_of_persons: "2",
            location: "Newark",
            date: "25/03/23",
            time: "08:00 a.m.",
            status: "accept",
        },
        {
            reservation_id: "0077766",
            name: "name",
            email: "test@example.com",
            no_of_persons: "2",
            location: "Allentown",
            date: "25/03/23",
            time: "08:00 a.m.",
            status: "accept",
        },
        {
            reservation_id: "0077766",
            name: "name",
            email: "test@example.com",
            no_of_persons: "2",
            location: "Norristown",
            date: "25/03/23",
            time: "08:00 a.m.",
            status: "reject",
        },
        {
            reservation_id: "0077766",
            name: "name",
            email: "test@example.com",
            no_of_persons: "2",
            location: "Newark",
            date: "25/03/23",
            time: "08:00 a.m.",
            status: "accept",
        },
        {
            reservation_id: "0077766",
            name: "name",
            email: "test@example.com",
            no_of_persons: "2",
            location: "Allentown",
            date: "25/03/23",
            time: "08:00 a.m.",
            status: "accept",
        },
        {
            reservation_id: "0077766",
            name: "name",
            email: "test@example.com",
            no_of_persons: "2",
            location: "Allentown",
            date: "25/03/23",
            time: "08:00 a.m.",
            status: "accept",
        },
        {
            reservation_id: "0077766",
            name: "name",
            email: "test@example.com",
            no_of_persons: "2",
            location: "Allentown",
            date: "25/03/23",
            time: "08:00 a.m.",
            status: "accept",
        },
    ],
    menuData: [
        {
            category: "Appetizers",
            sub_category: "Veg Appetizers",
            name: "Samosa",
            item_description: "Fried pastry with a savoury filling of spiced potatoes.....",
            price: "9.00",
        },
        {
            category: "Appetizers",
            sub_category: "Veg Appetizers",
            name: "Cut Mirchi",
            item_description: "Long green chilies, dipped in a chickpea and rice flour....",
            price: "9.00",
        },
        {
            category: "Appetizers",
            sub_category: "Veg Appetizers",
            name: "Spinach  Pakoda",
            item_description: "Crispy fried spinach dipped in a chickpea and rice flour....",
            price: "9.00",
        },
        {
            category: "Appetizers",
            sub_category: "Veg Appetizers",
            name: "Onion  Pakoda",
            item_description: "Crispy fried onions dipped in a chickpea and rice flour....",
            price: "9.00",
        },
    ],
    employeeData: [
        {
            name: "Name",
            phone_number: "9988774455",
            location: "Allentown",
            username: "Name",
            password: "password",
            role: "Location Manager",
        },
        {
            name: "Name",
            phone_number: "9988774455",
            location: "Norristown",
            username: "Name",
            password: "password",
            role: "Location Manager",
        },
        {
            name: "Name",
            phone_number: "9988774455",
            location: "Hillsborough",
            username: "Name",
            password: "password",
            role: "Location Manager",
        },
        {
            name: "Name",
            phone_number: "9988774455",
            location: "Allentown",
            username: "Name",
            password: "password",
            role: "Location Manager",
        },
    ]
}) 