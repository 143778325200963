import React, { Component } from "react";
import mixinsFunc from "../mixins";

export class BanquetHall extends Component {
  constructor(props) {
    super(props);
    this.mixins = mixinsFunc(this);
    this.state = {
      rowcount: 0,
      banquetHallTableData: [],
    };
    this.columns = [
      {
        title: "Reservation ID",
        dataIndex: "reservation_id",
        render: (text, record) => (
          <div className="text-center">{record.reservation_id}</div>
        ),
      },
      {
        title: "Name",
        dataIndex: "name",
      },
      {
        title: "Email",
        dataIndex: "email",
      },
      {
        title: "Type of Events",
        dataIndex: "event_type",
      },
      {
        title: "Date",
        dataIndex: "date",
      },
      {
        title: "Time",
        dataIndex: "time",
      },
      {
        title: "Remarks",
        dataIndex: "remarks",
      },
      {
        title: "Status",
        dataIndex: "status",
        render: (text, record) => {
          let status = [
            "NEW",
            "ACCEPT",
            "PAID",
            "REJECT",
            "CONFIRMED",
            "CONTACTED",
          ];
          let icons = {
            NEW: "text-new",
            ACCEPT: "text-accepted",
            REJECT: "text-rejected",
            PAID: "text-paid",
            CONFIRMED: "text-accepted",
            CONTACTED: "text-contacted",
          };
          let stsVal = text.toUpperCase();
          return (
            <div className="dropdown action-label text-center">
              <a
                className={Object.className({
                  "btn btn-sm btn-rounded sts-btn": true,
                  "dropdown-toggle": true,
                })}
                href="#"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <i
                  className={Object.className({
                    "fa fa-dot-circle-o": true,
                    [icons[stsVal]]: true,
                  })}
                />
                <span>{text.toLowerCase().toTitleCase()}</span>
              </a>
              <div className="dropdown-menu dropdown-menu-right">
                {status.map((value) => (
                  <div
                    className="dropdown-item"
                    onClick={() => this.updateValue(value, record, "status")}
                  >
                    <i className={"fa fa-dot-circle-o " + icons[value]} />
                    <span>{value.toLowerCase().toTitleCase()}</span>
                  </div>
                ))}
              </div>
            </div>
          );
        },
      },
    ];
  }
  componentDidMount() {
    this.setState({
      banquetHallTableData: this.mixins.banquetHallTableData,
    });
  }

  updateValue(value, record, name) {
    confirm(`Are you sure want to accept this Order`).promise.then(() => {
      console.log("status updated");
    });
  }
  editAction() {}

  deleteAction() {}

  render() {
    let { banquetHallTableData, rowcount } = this.state;
    let { columns = [] } = this;
    return (
      <AppWrapper title="Banquet Hall">
        <AppTable
          data={banquetHallTableData}
          columns={columns}
          reorder={true}
          total={rowcount}
          pageSize={10}
          editAction={(data) => this.editAction(data)}
          deleteAction={(data) => this.deleteAction(data)}
        ></AppTable>
      </AppWrapper>
    );
  }
}

export default connect(BanquetHall);
