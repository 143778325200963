import React, { Component } from 'react'

export class UngardedLayout extends Component {
  render() {
    return (
        this.props.children
    )
  }
}

export default UngardedLayout