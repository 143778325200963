import React, { Component } from "react";
import mixinsFunc from "../mixins";

export class DineInOrders extends Component {
  constructor(props) {
    super(props);
    this.mixins = mixinsFunc(this);
    this.state = {
      rowcount: 0,
      dineInOrderData: [],
      orderLists: [],
    };
    this.columns = [
      {
        title: "Table No.",
        dataIndex: "tnums",
        render: (text, record) => (
          <div className="text-center">{record.tnums}</div>
        ),
      },
      {
        title: "Email",
        dataIndex: "email",
      },
      {
        title: "Order List",
        dataIndex: "order_list",
        render: (text, record) => (
          <button
            type="button"
            className="list_btn"
            onClick={(e) => this.showOrderLists(e, record)}
          >
            Menu List
          </button>
        ),
      },
      {
        title: "Location",
        dataIndex: "location",
      },
      {
        title: "Bill Amt.",
        dataIndex: "bill_amt",
      },
      {
        title: "Status",
        dataIndex: "status",
        render: (text, record) => {
          let status = ["NEW", "ACCEPTED", "REJECTED", "PAID"];
          let icons = {
            NEW: "text-new",
            ACCEPTED: "text-accepted",
            REJECTED: "text-rejected",
            PAID: "text-paid",
          };
          let stsVal = text.toUpperCase();
          return (
            <div className="dropdown action-label text-center">
              <a
                className={Object.className({
                  "btn btn-sm btn-rounded sts-btn": true,
                  "dropdown-toggle": true,
                })}
                href="#"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <i
                  className={Object.className({
                    "fa fa-dot-circle-o": true,
                    [icons[stsVal]]: true,
                  })}
                />
                <span>{text.toLowerCase().toTitleCase()}</span>
              </a>
              <div className="dropdown-menu dropdown-menu-right">
                {status.map((value) => (
                  <div
                    className="dropdown-item"
                    onClick={() => this.updateValue(value, record, "status")}
                  >
                    <i className={"fa fa-dot-circle-o " + icons[value]} />
                    <span>{value.toLowerCase().toTitleCase()}</span>
                  </div>
                ))}
              </div>
            </div>
          );
        },
      },
    ];
  }
  componentDidMount() {
    this.setState({
      dineInOrderData: this.mixins.dineInOrderData,
    });
  }

  updateValue(value, record, name) {
    confirm(`Are you sure want to accept this Order`).promise.then(() => {
      console.log("status updated");
    });
  }

  showOrderLists(e, record) {
    e.preventDefault();
    console.log("Order List", record);
    this.setState({
      orderLists: record.order_list,
    });

    let orderListModal = new Modal(document.getElementById("orderListModal"));
    orderListModal.show();
  }

  editAction() {}

  deleteAction() {}

  render() {
    let { dineInOrderData, rowcount } = this.state;
    let { columns = [] } = this;
    return (
      <>
        <AppWrapper title="Dine In Orders">
          <AppTable
            data={dineInOrderData}
            columns={columns}
            reorder={true}
            total={rowcount}
            pageSize={10}
            editAction={(data) => this.editAction(data)}
            deleteAction={(data) => this.deleteAction(data)}
          ></AppTable>
        </AppWrapper>

        <AppOrderLists id="orderListModal">
          <div className="order_list_box">
            {this.state.orderLists?.map((list) => (
              <OrderList
                title={list.dish}
                price={list.price}
                quantity={list.quantity}
              />
            ))}
          </div>
        </AppOrderLists>
      </>
    );
  }
}

export default connect(DineInOrders);

export const OrderList = ({ title, price, quantity }) => (
  <div className="order_list_card">
    <div className="row mx-auto px-0 g-3">
      <div className="col-12 col-lg-6">
        <div className="d-flex align-items-start">
          <img src={imagepaths.listIcon} alt="listIcon" />
          <div className="ms-3">
            <p className="dish_name mb-2">{title}</p>
            <p className="price m-0 fw-bold">{`$ ${price.toFixed(2)}`}</p>
          </div>
        </div>
      </div>
      <div className="col-12 col-lg-6 d-flex justify-content-around justify-content-lg-between">
        <div>
          <p className="mb-2">Quantity :</p>
          <p className="qty mb-0">{quantity}</p>
        </div>
        <div>
          <p className="mb-2">Amount :</p>
          <p className="qty mb-0">$ {price.toFixed(2)}</p>
        </div>
      </div>
    </div>
  </div>
);
