import { Col } from "antd";
import React from "react";

const AppColFields = ({ data, colClass }) => {
  return (
    <>
      {Object.entries(data).map(([key, value]) => (
        <Col className={"p-2 col-12 " + colClass} key={key}>
          <span className="key">{key.replace(/_/g, " ")}</span>
          <span className="me-3">:</span>
          <span className="value">{value}</span>
        </Col>
      ))}
    </>
  );
};

export default AppColFields;
