import React, { Component } from "react";

export class AppUploadFileArea extends Component {
  constructor(props) {
    super(props);
    this.refs = {
      inputRef: React.createRef(),
    };
    this.state = {
      filevalue: null,
    };
  }
  componentDidMount() {}

  render() {
    let { props } = this;
    return (
      <div className="file_area_input_outer">
        <input
          className="file_area_input"
          type="file"
          id="inputFile"
          {...props}
        />
      </div>
    );
  }
}

export default connect(AppUploadFileArea);
