import React, { Component } from "react";
import Navbar from "../admin/Navbar";
import Sidebar from "../admin/Sidebar";
export class ClientLayout extends Component {
  constructor(props) {
    super(props);
    this.state = { menu: false };
  }
  toggleMobileMenu = () => {
    this.setState({ menu: !this.state.menu });
  };
  render() {
    return (
      <div
        className={`ClientLayout main-wrapper ${
          this.state.menu ? "slide-nav" : ""
        }`}
      >
        <div className="main-wrapper">
          <div className="page-content">{this.props.children}</div>
        </div>
      </div>
    );
  }
}

export default ClientLayout;
