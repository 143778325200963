import React, { useEffect, useRef } from "react";

const AppAlertModal = (props) => {
  let {
    message = "Alert Message",
    line1 = "",
    line2 = "",
    className = "",
    closeIcon = "fa fa-close",
    contentClass = "",
    onClose = () => "",
    alert = "",
    loader = false,
    title = "",
    modaltype = "",
  } = props;
  let attrs = Object.except(props, [
    "title",
    "className",
    "role",
    "closeIcon",
    "contentClass",
  ]);
  const closebtn = useRef();
  useEffect(() => {
    const { onLoad = () => "" } = props;
    onLoad({ closebtn });
  }, []);
  return (
    <>
      <div
        className={"modal custom-modal fade " + className}
        role="dialog"
        data-bs-backdrop="static"
        {...attrs}
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div
            className={`modal-content ${
              modaltype !== "info" ? "alert_modal_content" : ""
            }`}
          >
            {loader == "true" ? (
              <div className="modal-body">
                <div className="circle_loader_container">
                  <div className="circle_loader"></div>
                </div>
                <p className="text-center center_box">{message}</p>
              </div>
            ) : (
              <>
                <div className="modal-header">
                  {title && <h3>{title}</h3>}
                  <button
                    type="button"
                    ref={closebtn}
                    className="close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    id="bsmodalclose"
                    onClick={onClose}
                  >
                    <span aria-hidden="true">
                      <i className={closeIcon}></i>
                    </span>
                  </button>
                </div>
                <div className="modal-body">
                  {alert === "success" && (
                    <i className="fa fa-check-circle circle_icon"></i>
                  )}
                  {alert === "error" && (
                    <i className="fa fa-times-circle circle_icon error"></i>
                  )}
                  <p
                    className={`message_para ${
                      modaltype !== "info" ? "text-center center_box" : ""
                    }`}
                  >
                    {message}
                    <span className="d-block color-green">
                      {line1 && line1}
                    </span>
                    <br />
                    <span className="color-red">{line2 && line2}</span>
                  </p>
                  {/* <div className="form-group text-center mt-5">
                    <button
                      type="button"
                      ref={closebtn}
                      className="btn btn-primary"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      onClick={onClose}
                    >
                      Close
                    </button>
                  </div> */}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default AppAlertModal;
