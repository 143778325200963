export default (node) => ({

    menuCategories: [
        // {
        //     name: "allitems",
        //     label: "All Items",
        // },
        {
            name: "appetizers",
            label: "Appetizer",
        },
        {
            name: "maincourse",
            label: "Main Course",
        },
        {
            name: "biryani",
            label: "Biryani",
        },
        {
            name: "dosa",
            label: "Dosa",
        },
        {
            name: "naan",
            label: "Naan",
        },
        {
            name: "dessert_and_beverages",
            label: "Deserts & Beverages",
        },
    ],
    appetizerData: [
        {
            img: imagepaths.biryaniIcon,
            title: "Cauliflower Items"
        },
        {
            img: imagepaths.entreeIcon,
            title: "Panner Items"
        },
        {
            img: imagepaths.biryaniIcon,
            title: "Chicken Items"
        },
        {
            img: imagepaths.entreeIcon,
            title: "Goat Items"
        },
        {
            img: imagepaths.biryaniIcon,
            title: "Seafood Items"
        },
        {
            img: imagepaths.entreeIcon,
            title: "Tandoor Items"
        },
    ],
    mainCourseData: [
        {
            img: imagepaths.biryaniIcon,
            title: "Biryani Item"
        },
        {
            img: imagepaths.entreeIcon,
            title: "Entree Item"
        },
        {
            img: imagepaths.biryaniIcon,
            title: "Dosa Item"
        },
        {
            img: imagepaths.entreeIcon,
            title: "Roti Item"
        },
        {
            img: imagepaths.biryaniIcon,
            title: "Lunch Platter"
        },
    ],
    dessertAndBeveragesData: [
        {
            img: imagepaths.biryaniIcon,
            title: "Desserts"
        },
        {
            img: imagepaths.entreeIcon,
            title: "Beverages"
        },
    ],
    biryaniItemsData: [
        {
            img: imagepaths.biryaniIcon,
            title: "Chicken Biryani",
            foodCategory: "nonveg",
            price: "$25.50"
        },
        {
            img: imagepaths.biryaniIcon,
            title: "Mutton Biryani",
            foodCategory: "nonveg",
            price: "$25.50"
        },
        {
            img: imagepaths.biryaniIcon,
            title: "Veg Biryani",
            foodCategory: "veg",
            price: "$25.50"
        },
    ]
});