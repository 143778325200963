/* global imagepaths connect React Component */
import AdminLayout from "./admin/AdminLayout";
import ClientLayout from "./client/ClientLayout";
import UngardedLayout from "./ungaurded/ungaurded";

export class AppLayout extends Component {
  constructor(props) {
    super(props);
    props.initialconstruct.bind(this)("AppLayout");
    window.alert = props.mixin.alert;
    window.loader = props.mixin.loader;
    window.confirm = props.mixin.confirm;
    window.prompt = props.mixin.prompt;
    window.success = props.mixin.success;
    window.failure = props.mixin.failure;
  }

  isAdmin() {
    return this.props.location.pathname.startsWith("/admin");
  }
  isClient() {
    return this.props.location.pathname.startsWith("/client");
  }
  isUngarded() {
    return !(this.isAdmin() || this.isClient());
  }

  render() {
    let { props } = this;
    return (
      <>
        {this.isAdmin() && (
          <AdminLayout>
            {this.props.children}
            {(this.props.store.requests || []).map((request, i) => (
              <AppModal
                active={!!request.message}
                footer={["alert", "confirm", "prompt"].includes(request.type)}
                key={i}
                {...request}
                onClose={() => mixins.popRequest(request.id)}
                onCancel={() => mixins.popRequest(request.id)}
              >
                <div className="min-w-[300px] text-center">
                  <i className="animate-spin pi pi-fw pi-spinner h-3 w-3"></i>
                  <h4>{request.message}</h4>
                </div>
              </AppModal>
            ))}
          </AdminLayout>
        )}
        {this.isClient() && (
          <ClientLayout>
            {this.props.children}
            {(this.props.store.requests || []).map((request, i) => (
              <AppModal
                active={!!request.message}
                footer={["alert", "confirm", "prompt"].includes(request.type)}
                key={i}
                {...request}
                onClose={() => mixins.popRequest(request.id)}
                onCancel={() => mixins.popRequest(request.id)}
              >
                <div className="min-w-[300px] text-center">
                  <i className="animate-spin pi pi-fw pi-spinner h-3 w-3"></i>
                  <h4>{request.message}</h4>
                </div>
              </AppModal>
            ))}
          </ClientLayout>
        )}
        {this.isUngarded() && (
          <UngardedLayout>
            {this.props.children}
            {(this.props.store.requests || []).map((request, i) => (
              <AppModal
                active={!!request.message}
                footer={["alert", "confirm", "prompt"].includes(request.type)}
                key={i}
                {...request}
                onClose={() => mixins.popRequest(request.id)}
                onCancel={() => mixins.popRequest(request.id)}
              >
                <div className="min-w-[300px] text-center">
                  <i className="animate-spin pi pi-fw pi-spinner h-3 w-3"></i>
                  <div>{request.message}</div>
                </div>
              </AppModal>
            ))}
          </UngardedLayout>
        )}
      </>
    );
  }
}

export default connect(AppLayout);
