export default {
	SCHOOLS_LIST: [
		{
			school_name: "school name",
			admin_name: "admin name",
			mobile_number: "9878767656",
			email_id: "test@example.com",
			onboarding_date: "1 Jan 2023",
			city: "Location",
			state: "TS",
		},
	],
};
