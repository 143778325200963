/* global imagepaths */
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { alphaNumericPattern, emailrgx } from "../../constants";

const schema = yup.object({
  email_address: yup
    .string()
    .matches(emailrgx, "Email is required")
    .required("Email is required")
    .trim(),
  password: yup.string().min(8).max(8).required("Password is required").trim(),
});

const Loginpage = (props) => {
  const [eye, seteye] = useState(true);
  const {
    handleSubmit,
    control,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = (data) => {
    console.log("data", data, props);
    // navigate("/admin/dashboard");
    props.api
      .apiLogin(data)
      .then((res) => {
        // console.log("res from api:", { res });
        navigate("/admin/dashboard");
        clearErrors("password");
      })
      .catch(() => {
        setError("password", {
          message: "login failed",
        });
      });
  };

  const onEyeClick = () => {
    seteye(!eye);
  };
  const EyeAction = () => (
    <span
      onClick={onEyeClick}
      className={`fa toggle-password" ${eye ? "fa-eye-slash" : "fa-eye"}`}
    />
  );
  return (
    <AppUngardWrapper title="Login">
      <div className="account-box">
        <div className="account-wrapper">
          <div className="account-logo">
            <Link to="/login">
              <img src={imagepaths.headerlogo} alt="headerlogo" />
            </Link>
          </div>
          <h3 className="account-title">Login</h3>
          <p className="account-subtitle">Access to our dashboard</p>
          <div>
            <form onSubmit={handleSubmit(onSubmit)}>
              <AppYupInput
                name={"email_address"}
                control={control}
                errors={errors}
                label="Email ID"
              />
              <AppYupInput
                name={"password"}
                type={eye ? "password" : "text"}
                control={control}
                errors={errors}
                label="Password"
                slots={{ right: EyeAction }}
                className=""
              />
              <div className="form-group text-center mb-3">
                <button className="btn btn-primary account-btn" type="submit">
                  Login
                </button>
              </div>
              <div className="row px-0 mx-auto mt-2 mb-4">
                <div className="col-auto">
                  <Link className="grey_text" to="/forgotpassword">
                    Forgot password?
                  </Link>
                </div>
                <div className="col"></div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </AppUngardWrapper>
  );
};

export default connect(Loginpage);
