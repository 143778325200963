/* global imagepaths */
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { alphaNumericPattern, emailrgx } from "../../constants";

const schema = yup.object({
  email_address: yup
    .string()
    .matches(emailrgx, "Email is required")
    .required("Email is required")
    .trim(),
  password: yup.string().min(8).max(8).required("Password is required").trim(),
});

const SignUp = (props) => {
  const [eye, seteye] = useState(true);
  const {
    handleSubmit,
    control,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = (data) => {
    console.log("data", data);
    // props.api.apiLogin(data).then(()=>{
    // });
    navigate("/admin/dashboard");
  };

  const onEyeClick = () => {
    seteye(!eye);
  };
  const EyeAction = () => (
    <span
      onClick={onEyeClick}
      className={`fa toggle-password" ${eye ? "fa-eye-slash" : "fa-eye"}`}
    />
  );
  return (
    <AppUngardWrapper title="Signup">
      <div className="account-box">
        <div className="account-wrapper">
          <div className="account-logo">
            <Link to="/login">
              <img src={imagepaths.headerlogo} alt="headerlogo" />
            </Link>
          </div>
          <h3 className="account-title">Sign - Up</h3>
          <p className="account-subtitle">Access to our dashboard</p>
          <div>
            <form onSubmit={handleSubmit(onSubmit)}>
              <AppYupInput
                name={"name"}
                control={control}
                errors={errors}
                label="Name"
                defaultValue="Yasha Agarwal"
                className=""
              />
              <AppYupInput
                name={"email_address"}
                control={control}
                errors={errors}
                label="Email ID"
                defaultValue="Emailid@gmail.com"
                className=""
              />
              <div className="form-group text-center mb-3">
                <button className="btn btn-primary account-btn" type="submit">
                  SIGN-UP
                </button>
              </div>
              <div className="row px-0 mx-auto">
                <p style={{ color: "#C6C6C6" }}>
                  Already a member?
                  <Link className="ms-2" to="/login">
                    Login
                  </Link>
                </p>
              </div>
            </form>
          </div>
        </div>
      </div>
    </AppUngardWrapper>
  );
};

export default connect(SignUp);
