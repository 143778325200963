import api from "../../api/apisetup";
window.api = api;
const files = require.context('./', true, /\.js$/i);
const file_keys = files.keys().filter(key=>key!=="./index.js");
const apifiles = require.context('../../', true, /\.api\.js$/i);
const apifilekeys = apifiles.keys();
export const apis = {
    ...file_keys.map(key=>files(key).default).reduce((o,n)=>({...o,...n}),{}),
    ...apifilekeys.map(key=>apifiles(key).default).reduce((o,n)=>({...o,...n}),{})
}
console.log({apis});
export default apis;