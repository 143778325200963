import React from 'react';
import { Link } from "react-router-dom";

function AppLinkTabs({ className="", data=[],overideClass=false, linkClass=""}) {
  return (
    <div className={Object.className({"flex flex-wrap mb-6":!overideClass,[className]:true})}>
        {
            data.map((link,i)=>(
                <Link 
                    key={i}
                    className={Object.className({
                      'btn rounded-full border mx-1 min-w-[150px] m-clip ':true,
                      'btn-warning':link.path==window.location.pathname,
                      [linkClass]:true
                    })} 
                    href="#" 
                    to={link.path} >
                        {link.label}
                </Link>
            ))
        }
    </div>
  )
}

export default AppLinkTabs;