export default {
  "Appetizers": [
    "Veg Appetizers",
    "Chicken Appetizers",
    "Goat Appetizers",
    "Seafood Appetizers",
  ],
  "Main Course": [
    "Veg Entrees",
    "Chicken Entrees",
    "Goat Entrees",
    "Seafood Entrees",
    "Egg Entrees",
  ],
  "Biryani": ["Biryani"],
  "Dosa": ["Dosa"],
  "Naan": ["Naan"],
  "Tandoor": ["Tandoor"],
  "Dessert": ["Dessert"],
  "Beverages": ["Beverages"],
}