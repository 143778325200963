import React, { Component } from "react";
import { Link } from "react-router-dom";
import PreviewModal from "../PreviewModal";
import "../party_orders.scss";
import moment from "moment/moment";
export class Configurations extends Component {
  constructor(props) {
    super(props);
    props.initialconstruct.bind(this)("Configurations");
    this.state = {
      rowcount: 0,
      search: {
        limit: 10,
        page: 1,
        order_by: "updatedAt",
        order_direction: "DESC",
      },
      form: {},
      items: [{}],
      selectedMenuLists: [],
      selectedCategory: "",
      grandValue: 0,
      popupdata: {},
      editdata: {},
      readonly: false,
      errormessage: {},
    };
    this.previewModalRef = React.createRef();
  }

  // get isAdmin() {
  //   return this.props.store.user?.employee_role === "admin";
  // }

  get has_edit() {
    return !!this.props.urlparams.id;
  }

  get formEle() {
    return this.refs?.form?.form?.current;
  }

  get formData() {
    let formData = this.formEle?.getData() || {};
    let parsedData = parseInput(formData);
    parsedData.fields = (parsedData.fields || []).map((field) => {
      let { isSelect } = this.checkFieldTypes({
        ...field,
        key: field._id,
      });
      return {
        ...field,
        hasSelect: isSelect,
      };
    });

    return parsedData;
  }

  componentDidMount() {
    if (this.props.urlparams.id) {
      this.getPartyOrderById();
      this.setState({
        readonly: this.props.urlparams?.readonly === "true" ? true : false,
      });
    }
  }

  getPartyOrderById() {
    let payload = {
      order_id: this.props.urlparams.id,
    };
    this.props.api.apiGetPartyOrderById(payload).then(({ data, count }) => {
      console.log({ apiGetPartyOrderById: data });
      this.setState({
        editdata: data,
        readonly:
          data?.status != "OPEN" || this.props.urlparams?.readonly === "true",
        items: data?.items.map((o) => {
          let { isSelect } = this.checkFieldTypes({
            ...o,
            key: o._id,
          });
          let menuOptions = [];

          let optionArray = JSON.parse(sessionStorage.getItem("menuData"))
            ?.filter((item) => {
              if (item.item_category === o.category) {
                let targetObj = item.item_category === o.category;
                menuOptions.push(item);
                return targetObj && targetObj;
              }
            })
            .map((item, i) => item?.item_name);

          menuOptions = optionArray?.options();
          return {
            ...o,
            selectedprice: o.price,
            selectedprice: o.price,
            menuOptions: menuOptions,
            hasSelect: isSelect || false,
          };
        }),
      });
      // setting default values
      let discountPercentageInput = document.querySelector(
        "#discount-percent-input"
      );
      let discountAmountInput = document.querySelector(
        "#discount-amount-input"
      );
      let grandTotalInput = document.querySelector("#grand-total-input");
      let deliveryInput = document.querySelector("#delivery-charge-input");
      let taxPercentInput = document.querySelector("#tax-percent-input");

      if (
        this.props.urlparams.readonly === "true" ||
        this.props.urlparams.status !== "OPEN"
      ) {
        taxPercentInput.disabled = true;
        document.querySelectorAll(".customItemInput input").forEach((item) => {
          item.setAttribute("readonly", true);
        });
      }
      discountPercentageInput.defaultValue = data?.discount_percent;
      discountAmountInput.defaultValue = data?.discount_amount;
      grandTotalInput.defaultValue = data?.grand_total;
      deliveryInput.defaultValue = data?.delivery_charge;
    });
  }

  checkFieldTypes(field) {
    let isFreeText = ["text", "textarea"].includes(field.key);
    let isSelect = ["select", "checkbox", "radio"].includes(field.key);
    let isFile = ["file"].includes(field.key);
    let isDate = ["date"].includes(field.key);

    return {
      hasSelect: isSelect,
      isFile,
      isSelect,
      isDate,
      isFreeText,
    };
  }

  addField(e) {
    let { items } = this.state;
    console.log({ items });
    items = [...items, {}];
    this.setState({
      items,
    });
  }

  deleteField(index) {
    const removeLocal = () => {
      let { items } = this.state;
      items = items.filter((v, i) => i != index);
      this.setState(
        {
          items,
        },
        () => {
          this.calculateGrandTotal();
        }
      );
    };
    removeLocal();
  }

  openPreviewModal() {
    this.previewModalRef.current.click();
  }

  closeModal() {
    document.querySelector("#preview_modal #bsmodalclose").click();
  }

  handleTypes(field) {
    let menuOptions;

    // console.log(menucategories[field.key]);
    let pricelist = [];
    let itemNamesArr = JSON.parse(sessionStorage.getItem("menuData"))
      ?.filter((item) => {
        if (item.item_category === field.key) {
          let targetObj = item.item_category === field.key;
          pricelist.push(item);
          return targetObj && targetObj;
        }
      })
      .map((item, i) => item?.item_name);
    // console.log({ itemNamesArr });
    // console.log({ pricelist });
    // this.setState({
    //   selectedMenuLists: pricelist,
    // });
    // console.log({ lowerItems });
    menuOptions = itemNamesArr?.options();

    return {
      menuOptions,
      pricelist,
    };
  }

  handleCategoryTypeChange(v, i) {
    console.log({ handleCategoryTypeChange: v[0], i });

    if (v) {
      let itemNameFormGroup = document.querySelector(
        `[name='appinput-items.${i}.item_name'].form-group`
      );
      let { values: itemValues = [] } =
        itemNameFormGroup.vnode.refs.select.state;
      if (itemValues.length) {
        let obj = JSON.parse(sessionStorage.getItem("menuData"))?.filter(
          (obj) => obj?.item_name === itemValues?.[0].key
        );
        if (obj[0]?.item_category !== v[0].key) {
          itemNameFormGroup.vnode.refs.select.clearAll();
        }
      }
    }

    const setFields = (state) => {
      // let { isSelect, MENU_OPTIONS, pricelist } = this.handleTypes(v[0]);
      let result = this.handleTypes(v[0]);
      console.log(result);
      let { isSelect, menuOptions, pricelist } = result;
      let items = Object.setNested(
        state.items,
        `${i}.hasSelect`,
        result.isSelect
      );
      items = Object.setNested(items, `${i}.menuOptions`, menuOptions);
      items = Object.setNested(items, `${i}.withprice`, pricelist);
      // items = Object.setNested(items, `${i}.selectedprice`, 100);
      // items = Object.setNested(items, `${i}.quantity`, 0);
      return items;
    };
    this.setState((state) => ({
      items: setFields(state),
      selectedCategory: v[0].key,
    }));
  }

  handleMenuItemChange(v, i) {
    let targetfield = JSON.parse(sessionStorage.getItem("menuData"))?.filter(
      (item, i) => {
        if (item.item_name === v[0]?.key) {
          return item;
        }
      }
    );
    console.log({ handleMenuItemChange: v[0].key, i, targetfield });
    const setFields = (state) => {
      let { isSelect, pricelist, menuOptions } = this.handleTypes({
        key: this.state.selectedCategory,
        value: this.state.selectedCategory,
      });

      let items = Object.setNested(state.items, `${i}.hasSelect`, isSelect);
      items = Object.setNested(items, `${i}.menuOptions`, menuOptions);
      items = Object.setNested(items, `${i}.withprice`, pricelist);
      items = Object.setNested(items, `${i}.selectedprice`, 0);
      // items = Object.setNested(items, `${i}.quantity`, 1);
      items = Object.setNested(
        items,
        `${i}.selectedprice`,
        targetfield[i]?.item_retail_price
      );

      return items;
    };

    this.setState((state) => ({
      items: setFields(state),
    }));
  }

  handlePricePerTrayChange(e, i) {
    // console.log({ handleMenuItemChange: e.target.value, i });
    let pricePerTray = e.target.value;
    if (pricePerTray === "") {
      pricePerTray = 0;
    } else {
      pricePerTray = JSON.parse(pricePerTray);
    }

    const setFields = (state) => {
      let items = Object.setNested(
        state.items,
        `${i}.price_per_tray`,
        pricePerTray
      );
      items = Object.setNested(
        items,
        `${i}.selectedprice`,
        pricePerTray * state.items?.[i]?.quantity
      );
      return items;
    };
    this.setState(
      (state) => ({
        items: setFields(state),
      }),
      () => {
        this.calculateGrandTotal();
      }
    );
  }

  handleQuantityChange(e, i) {
    // console.log({ handleMenuItemChange: e.target.value, i });
    let qty = e.target.value;
    if (qty === "") {
      qty = 0;
    } else {
      qty = JSON.parse(qty);
    }

    const setFields = (state) => {
      let { pricelist } = this.handleTypes({
        key: this.state.selectedCategory,
        value: this.state.selectedCategory,
      });
      let items = Object.setNested(state.items, `${i}.quantity`, qty);
      items = Object.setNested(state.items, `${i}.withprice`, pricelist);
      items = Object.setNested(
        items,
        `${i}.selectedprice`,
        state.items?.[i]?.price_per_tray * qty
      );

      return items;
    };

    this.setState(
      (state) => ({
        items: setFields(state),
      }),
      () => {
        this.calculateGrandTotal();
      }
    );
  }

  // ----------------------------------------------------------------

  calculateGrandTotal() {
    // Get user inputs
    let arrs = this.state.items;
    // console.log({ arrs });
    let totalPrice;
    arrs = arrs.filter((obj) => Object.keys(obj).length > 0);

    totalPrice = arrs.reduce((sum, obj) => {
      if (obj.selectedprice !== NaN) {
        // console.log(
        //   "reduce calc: ",
        //   sum + (obj.price_per_tray + obj.selectedprice) * obj.quantity
        // );
        return sum + obj.price_per_tray * obj.quantity;
      }
    }, 0);
    console.log({ totalPrice });

    let discountPercentageInput = document.querySelector(
      "#discount-percent-input"
    );

    let taxPercentageInput = document.querySelector("#tax-percent-input");
    let discountAmountInput = document.querySelector("#discount-amount-input");
    let deliveryInput = document.querySelector("#delivery-charge-input");
    let grandTotalInput = document.querySelector("#grand-total-input");
    let total = totalPrice || 0;
    let discountPercentage = parseFloat(discountPercentageInput.value) || 0;
    let taxPercentage = parseFloat(taxPercentageInput.value) || 0;
    let discountAmount = parseFloat(discountAmountInput.value) || 0;
    let deliveryCharge = parseFloat(deliveryInput.value) || 0;

    // Calculate discount
    let discount = (total * discountPercentage) / 100;

    // Calculate grand total
    let grandTotal = total - discount + deliveryCharge;

    // Display results
    let Total = total.toFixed(2);
    let Discount = discount.toFixed(2);
    let DeliveryCharge = deliveryCharge.toFixed(2);
    let GrandTotal = grandTotal.toFixed(2);
    let taxAmount = (taxPercentage / 100) * grandTotal;
    console.log({ taxPercentage, taxAmount });

    let finalAmount = Number(GrandTotal) + taxAmount;
    grandTotalInput.value = finalAmount;

    discountAmountInput.value = Discount;
    console.log("grandcalc", {
      Total,
      Discount,
      DeliveryCharge,
      taxAmount,
      GrandTotal,
    });
  }

  // ----------------------------------------------------------------
  handleDateChange(value) {
    if (value) {
      let weekday = new Date(value).getDayFullName();
      let weekdayInput = document.querySelector("[name='weekday']");
      weekdayInput.defaultValue = weekday;

      console.log({ value, weekday });
    }
  }

  handleDeliveryModeChange(v) {
    let deliveryMode = v[0]?.key;

    let deliveryChargeInput = document.querySelector("#delivery-charge-input");

    if (deliveryMode === "Pickup") {
      deliveryChargeInput.value = 0;
      deliveryChargeInput.disabled = true;
      this.calculateGrandTotal();
    } else {
      deliveryChargeInput.disabled = false;
    }
  }

  getTotalPrice() {
    let arrs = this.state.items;
    // console.log({ arrs });
    let totalPrice;
    arrs = arrs.filter((obj) => Object.keys(obj).length > 0);

    totalPrice = arrs.reduce((sum, obj) => {
      if (obj.selectedprice !== NaN) {
        // console.log(
        //   "reduce calc: ",
        //   sum + (obj.price_per_tray + obj.selectedprice) * obj.quantity
        // );
        return sum + obj.price_per_tray * obj.quantity;
      }
    }, 0);
    let finalTotalInput = document.querySelector("#final-total-input");
    if (finalTotalInput) {
      // console.log({ totalPrice });
      finalTotalInput.value = totalPrice.toFixed(2);
    }
  }

  sumitForm(e, data, form) {
    let parsedPayload = parseInput(data);
    console.log({ parsedPayload, data });

    if (this.has_edit) {
      let payload = {
        ...parsedPayload,
        delivery_charge:
          parsedPayload?.delivery_charge?.length === 0
            ? "0"
            : parsedPayload?.delivery_charge,
        discount_percent:
          parsedPayload?.discount_percent?.length === 0
            ? "0"
            : parsedPayload.discount_percent,
      };
      if (this.props.urlparams.status !== "OPEN") {
        payload = {
          ...Object.only(payload, "status"),
        };
      } else {
        payload = {
          ...payload,
        };
      }
      console.log({ payload });

      this.api
        .apiUpdatePartyOrder(payload, { order_id: this.props.urlparams.id })
        .then((res) => {
          if (res) {
            success("Ordered Updated");
            this.navigate("/admin/party_orders");
          }
        });
    } else {
      this.setState({
        popupdata: parsedPayload,
      });
      this.openPreviewModal();
    }
  }

  handlePreviewSubmit() {
    let payload = {
      ...this.state.popupdata,
    };
    // console.log({ payload });
    this.api.apiAddPartyOrders(payload).then((res) => {
      if (res) {
        console.log({ apiAddPartyOrders: res });
        success("Ordered Successfully");
        this.closeModal();
        this.navigate("/admin/party_orders");
      }
    });
  }

  handleAccStep() {
    let formData = this.formEle?.getData() || {};
    let parsedData = parseInput(formData);

    console.log(parsedData);

    if (parsedData?.name !== "" && parsedData?.status !== "") {
      document.querySelector("#partyorderAcc2 button").click();
    }
  }

  handleMobileNo(e) {
    const numberReg = _enum?.REGEX?.number;
    const fieldValue = e.target.value;
    if (numberReg.test(fieldValue) && fieldValue.length === 10) {
      this.setState({
        errormessage: "",
      });
    } else {
      this.setState({
        errormessage: {
          name: "phone_number",
          message: "Please enter a valid Mobile Number",
        },
      });
    }
  }

  render() {
    let { props, has_edit } = this;
    let { items, grandValue, popupdata, editdata, errormessage, readonly } =
      this.state;

    let statusReadonly = true;
    if (props.urlparams.readonly) {
      statusReadonly = true;
    } else if (
      props.urlparams.status === "DELIVERED" ||
      props.urlparams.status === "CANCELLED" ||
      props.urlparams.status === "OPEN"
    ) {
      statusReadonly = false;
    }
    this.getTotalPrice();

    return (
      <AppWrapper
        title={
          readonly
            ? "View Order"
            : props.urlparams.id
            ? "Edit Order"
            : "Create New"
        }
        isIconbreadcrum={true}
        backlink={"/admin/party_orders"}
      >
        <div className="col-12 col-md-6 d-flex align-items-center justify-content-md-end d-none">
          <Link
            className="btn modal_btn lg"
            ref={this.previewModalRef}
            data-bs-toggle="modal"
            data-bs-target="#preview_modal"
            // onClick={(...args) => this.addModalFields(...args)}
          >
            <i className="fa fa-plus me-2"></i>
            Add Item
          </Link>
        </div>
        <AppForm ref="form" onSubmit={(...args) => this.sumitForm(...args)}>
          <div class="accordion party_order_acc" id="partyorderAcc">
            <div class="accordion-item">
              <h2 class="accordion-header" id="partyorderAcc1">
                <button
                  class="accordion-button"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseOne"
                  aria-expanded="true"
                  aria-controls="collapseOne"
                >
                  <span>Customer & Event Information</span>
                </button>
              </h2>
              <div
                id="collapseOne"
                class="accordion-collapse collapse show"
                aria-labelledby="partyorderAcc1"
                data-bs-parent="#partyorderAcc"
              >
                <div class="accordion-body">
                  {/* CUSTOMER INFO */}
                  <div className="row mb-2">
                    <div className="col-12 col-md-3">
                      <AppInput
                        type="text"
                        label="Customer Name"
                        name="name"
                        defaultValue={editdata?.name || ""}
                        disabled={readonly}
                        required={true}
                        maxLength="50"
                        pattern="^[A-Za-z][a-zA-Z0-9\/ \\ ]*$"
                        invalidmessage="Value Should be Valid Aphanumeric Content"
                        placeholder="Enter Name"
                      ></AppInput>
                    </div>
                    <div className="col-12 col-md-3">
                      <AppInput
                        type="text"
                        label="Mobile Number"
                        name="phone_number"
                        defaultValue={editdata?.phone_number || ""}
                        readOnly={readonly}
                        pattern="[0-9]{10}"
                        placeholder="Enter Mobile No."
                        onChange={(...args) => this.handleMobileNo(...args)}
                        errorMessage={errormessage}
                      ></AppInput>
                    </div>
                    <div className="col-12 col-md-3">
                      <AppInput
                        type="text"
                        label="Address"
                        name="address"
                        defaultValue={editdata?.address || ""}
                        readOnly={readonly}
                        placeholder="Enter Address"
                      ></AppInput>
                    </div>
                    <div className="col-12 col-md-3">
                      <AppInput
                        type="select"
                        label="Status"
                        name="status"
                        defaultValue={editdata?.status || ""}
                        readOnly={statusReadonly}
                        onChange={(v) => {
                          console.log(v);
                          this.setState({
                            readOnly: true,
                          });
                        }}
                        options={_enum.ORDER_STATUS}
                        placeholder="Select One"
                        required={true}
                      ></AppInput>
                    </div>
                  </div>

                  {/* EVENT INFO */}
                  <div className="row mb-4">
                    <div className="col-12 col-md-3">
                      <AppInput
                        type="date"
                        label="Date"
                        name="date"
                        defaultValue={editdata?.date || ""}
                        readOnly={readonly}
                        onChange={(...args) => this.handleDateChange(...args)}
                        disabledDate={(current) => {
                          return current && current < moment().startOf("day");
                        }}
                        placeholder="Select Date"
                      ></AppInput>
                    </div>
                    <div className="col-12 col-md-3">
                      <AppInput
                        type="time"
                        label="Time"
                        name="time"
                        defaultValue={editdata?.time || ""}
                        readOnly={readonly}
                        placeholder="Select Time"
                      ></AppInput>
                    </div>
                    <div className="col-12 col-md-3">
                      <AppInput
                        type="text"
                        label="Weekday"
                        name="weekday"
                        defaultValue={editdata?.weekday}
                        readOnly={true}
                      ></AppInput>
                    </div>
                    <div className="col-12 col-md-3">
                      <AppInput
                        type="select"
                        label="Pickup / Delivery"
                        name="delivery_mode"
                        defaultValue={editdata?.delivery_mode || ""}
                        readOnly={readonly}
                        options={_enum.DELIVERY_MODE}
                        onChange={(v) => this.handleDeliveryModeChange(v)}
                        placeholder="Select One"
                      ></AppInput>
                    </div>
                  </div>

                  <div className="flex mt-4">
                    <div className="grow"></div>
                    <Link
                      to={"/admin/party_orders"}
                      className="btn cancel-btn ms-2 me-2"
                    >
                      Cancel
                    </Link>
                    <button
                      type="button"
                      className={Object.className({
                        "btn modal_btn lg": true,
                      })}
                      onClick={(...args) => this.handleAccStep(...args)}
                    >
                      {has_edit ? "Next" : "Save & Next"}
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="partyorderAcc2">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseTwo"
                  aria-expanded="false"
                  aria-controls="collapseTwo"
                >
                  <span>Menu Details</span>
                </button>
              </h2>
              <div
                id="collapseTwo"
                class="accordion-collapse collapse"
                aria-labelledby="partyorderAcc2"
                data-bs-parent="#partyorderAcc"
              >
                <div class="accordion-body">
                  {items.map((field, i) => (
                    <>
                      <div className="row position-relative" key={i}>
                        <div className="col-12 col-md-3">
                          <AppInput
                            type="select"
                            label="Category"
                            name={`items.${i}.category`}
                            defaultValue={field?.category || ""}
                            readOnly={readonly}
                            options={Object.keys(menucategories).options()}
                            onChange={(v) =>
                              this.handleCategoryTypeChange(v, i)
                            }
                            placeholder="Select Category"
                            required={true}
                          ></AppInput>
                        </div>
                        <div className="col-12 col-md-3">
                          <AppInput
                            type="select"
                            label="Item Name"
                            name={`items.${i}.item_name`}
                            defaultValue={field?.item_name || ""}
                            readOnly={readonly}
                            options={items[i].menuOptions}
                            // onChange={(v) => this.handleMenuItemChange(v, i)}
                            placeholder="Select Item"
                            required={true}
                          ></AppInput>
                        </div>
                        <div className="col-12 col-md-3">
                          <AppInput
                            type="text"
                            label="Custom Item Name"
                            name={`items.${i}.custom_item`}
                            defaultValue={field?.custom_item || ""}
                            readOnly={readonly}
                            placeholder="Custom Item Name"
                            className="customItemInput"
                          ></AppInput>
                        </div>
                        <div className="col-12 col-md-3">
                          <AppInput
                            type="select"
                            label="Tray Size"
                            name={`items.${i}.tray_size`}
                            defaultValue={field?.tray_size || ""}
                            readOnly={readonly}
                            options={_enum.TRAY_SIZE}
                            placeholder="Select Size"
                            required={true}
                          ></AppInput>
                        </div>
                        <div className="col-12 col-md-3">
                          <AppInput
                            type="number"
                            label="Price Per Tray"
                            name={`items.${i}.price_per_tray`}
                            defaultValue={field?.price_per_tray || ""}
                            readOnly={readonly}
                            placeholder="Enter Price in $"
                            onChange={(v) =>
                              this.handlePricePerTrayChange(v, i)
                            }
                            min="0"
                            required={true}
                          ></AppInput>
                        </div>
                        <div className="col-12 col-md-3">
                          <AppInput
                            type="number"
                            label="Quantity"
                            name={`items.${i}.quantity`}
                            defaultValue={field?.quantity || ""}
                            readOnly={readonly}
                            placeholder="Enter quantity"
                            onChange={(v) => this.handleQuantityChange(v, i)}
                            min="0"
                            required={true}
                          ></AppInput>
                        </div>
                        <div className="col-12 col-md-3">
                          <AppInput
                            type="select"
                            label="Spice Level"
                            name={`items.${i}.spice_level`}
                            defaultValue={field?.spice_level || ""}
                            readOnly={readonly}
                            options={_enum.SPICE_LEVEL}
                            placeholder="Select any"
                            required={true}
                          ></AppInput>
                        </div>
                        <div className="col-12 col-md-3">
                          <AppInput
                            type="number"
                            label="Price"
                            name={`items.${i}.price`}
                            value={items[i].selectedprice || ""}
                            readOnly={true}
                            controlClass="price-input"
                            min="0"
                          ></AppInput>
                        </div>
                        <div className="d-flex justify-end mb-3">
                          {i == items.length - 1 && (
                            <button
                              onClick={() => this.deleteField(i)}
                              className={Object.className({
                                "btn delete_btn text-end": true,
                                "d-none": readonly,
                              })}
                              type="button"
                            >
                              <i className="fa fa-trash-o text-error" />
                            </button>
                          )}
                        </div>
                      </div>
                      <hr />
                    </>
                  ))}
                  <div className="row mb-4">
                    <div className="col-12">
                      <button
                        className={Object.className({
                          "btn bg-transparent flex gap-2 color-red": true,
                          "d-none": readonly,
                        })}
                        type="button"
                        onClick={(e) => this.addField(e)}
                      >
                        <i className="fa fa-plus m-auto" />
                        <span className="">Add more items</span>
                      </button>
                    </div>
                  </div>

                  {/* BOTTOM CALCULATION BOX */}
                  <div className="row">
                    <div className="d-flex align-items-center justify-content-between w-100 calculation_input_outer">
                      <p className="row_heading">Total</p>
                      <AppInput
                        type="number"
                        label=""
                        name="total"
                        defaultValue={Number(editdata?.total) || 0}
                        id="final-total-input"
                        onChange={(v) => this.calculateGrandTotal(v)}
                        readOnly={true}
                        showdollaricon="true"
                        controlClass="border-0"
                        min="0"
                      ></AppInput>
                    </div>
                    <div className="d-flex align-items-center justify-content-between w-100 calculation_input_outer">
                      <p className="row_heading">Discount Percentage</p>
                      <AppInput
                        type="number"
                        name="discount_percent"
                        id="discount-percent-input"
                        className="discount_input"
                        showpercenticon="true"
                        onInput={(v) => this.calculateGrandTotal(v)}
                        defaultValue={editdata.discount_percent}
                        min={0}
                        step={0.01}
                        readOnly={readonly}
                      ></AppInput>
                    </div>
                    <div className="d-flex align-items-center justify-content-between w-100 calculation_input_outer">
                      <p className="row_heading">Discount Amount</p>
                      <AppInput
                        type="number"
                        name="discount_amount"
                        id="discount-amount-input"
                        className="discount_input"
                        showpercenticon="true"
                        onInput={(v) => this.calculateGrandTotal(v)}
                        defaultValue={editdata.discount_amount}
                        min={0}
                        step={0.01}
                        readOnly={true}
                      ></AppInput>
                    </div>
                    <div className="d-flex align-items-center justify-content-between w-100 calculation_input_outer">
                      <p className="row_heading">Delivery Charges</p>
                      <AppInput
                        type="number"
                        name="delivery_charge"
                        id="delivery-charge-input"
                        showdollaricon="true"
                        onInput={(v) => this.calculateGrandTotal(v)}
                        defaultValue={editdata.delivery_charge}
                        min="0"
                        readOnly={readonly}
                      ></AppInput>
                    </div>
                    <div className="d-flex align-items-center justify-content-between w-100 calculation_input_outer">
                      <p className="row_heading">Tax Percentage</p>
                      <AppInput
                        type="number"
                        name="tax_percent"
                        id="tax-percent-input"
                        className="tax_percent_input"
                        showdollaricon="true"
                        onInput={(v) => this.calculateGrandTotal(v)}
                        defaultValue={editdata.tax_percent}
                        min="0"
                        readOnly={readonly}
                      ></AppInput>
                    </div>
                    <hr />
                    <div className="d-flex align-items-center justify-content-between w-100 calculation_input_outer">
                      <p className="row_heading">Grand Total</p>
                      <AppInput
                        type="number"
                        name="grand_total"
                        id="grand-total-input"
                        placeholder=""
                        showdollaricon="true"
                        controlClass="border-0"
                        readOnly={true}
                      ></AppInput>
                    </div>
                  </div>

                  <div className="flex mt-4">
                    <div className="grow"></div>
                    <Link
                      to={"/admin/party_orders"}
                      className="btn cancel-btn ms-2 me-2"
                    >
                      Cancel
                    </Link>
                    {!statusReadonly &&
                      this.props.urlparams.status !== "OPEN" && (
                        <button
                          className={Object.className({
                            "btn modal_btn lg": true,
                          })}
                        >
                          Update
                        </button>
                      )}
                    <button
                      className={Object.className({
                        "btn modal_btn lg": true,
                        "d-none": readonly,
                      })}
                    >
                      {has_edit ? "Update" : "Save & Preview"}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </AppForm>
        <PreviewModal
          id="preview_modal"
          title="Preview"
          submitText="Submit"
          data={popupdata}
          onSubmit={(...args) => this.handlePreviewSubmit(...args)}
          onClose={() =>
            this.setState({
              popupdata: {},
            })
          }
        />
      </AppWrapper>
    );
  }
}

export default connect(Configurations);
