/* eslint-disable */
import React, { Component } from 'react';
import 'bootstrap';
import $ from "jquery";
// import 'bootstrap';
import * as bootstrap from 'bootstrap';
import swal2 from "sweetalert2";

window.Modal = bootstrap.Modal;
window.swal2 = swal2;

import 'bootstrap/dist/js/bootstrap.bundle';
import 'font-awesome/css/font-awesome.min.css';

import '../assets/css/font-awesome.min.css';
import '../assets/css/line-awesome.min.css';

import 'bootstrap/dist/css/bootstrap.min.css';
import "../assets/css/bootstrap.min.css";

// Custom Style File
import '../assets/js/bootstrap.bundle.js';
import '../assets/css/select2.min.css';

import '../assets/js/popper.min.js';
import '../assets/js/app.js';
import '../assets/js/select2.min.js';


//import "../assets/js/bootstrap-datetimepicker.min.js";

import "../assets/js/multiselect.min.js";
import "../assets/plugins/bootstrap-tagsinput/bootstrap-tagsinput.css";
//import "../assets/css/bootstrap-datetimepicker.min.css";
import '../assets/css/style.css';
import '../helpers'
import "../imagepath"

window.$ = $;
window.React = React;
window.Component = Component;