import { UploadOutlined } from "@ant-design/icons";
import { Button, Upload } from "antd";
import { useEffect, useState, useRef } from "react";

const AppUploadFile = (props) => {
  // console.log({ AppUploadFile: props });
  const inputRef = useRef(null);
  const [filevalue, setFilevalue] = useState(null);
  const [filename, setFilename] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [fileobjvalue, setFileObjValue] = useState(null);
  const [uploadStatus, setUploadStatus] = useState("");
  const [fileList, setFileList] = useState([]);

  useEffect(() => {
    // console.log({ props });
    if (uploadStatus !== "removed") {
      if (props.defaultValue instanceof Array) {
        setFilevalue(props.defaultValue[0]?.attachment_id);
      } else if (
        props.defaultValue === "" ||
        props.defaultValue === undefined
      ) {
        setFileList([]);
      } else {
        setFilevalue(props.defaultValue);
        setImageUrl(props.imageurl);
        setFileList([
          {
            uid: filevalue,
            name: "file.png",
            status: "done",
            imageUrl: imageUrl,
            url: `${props.apis().api_base}/files/download/${
              props.defaultValue
            }`,
          },
        ]);
      }
    }
  }, [props.defaultValue]);

  const handleChange = (...args) => {
    let [info] = args;
    console.log("FILE: ", info.file);
    console.log("FILE NAME: ", info.file.name);
    console.log("FILE STATUS: ", info.file.status);

    setFileObjValue(info.file);
    setFilename(info.file.name);
    setUploadStatus(info.file.status);

    if (info.file.status === "done") {
      // setting uploaded filename
      let atag = document.querySelector(
        `[name="appinput-${props?.name}"] .ant-upload-list-item-name`
      );
      console.log(props?.name, atag);
      atag.title = info?.file?.name;
    }

    if (info.file.status === "uploading") {
      // swal2.fire("Uploading file please wait....");

      let payload = {
        foldername: props.foldername,
      };

      let reqdata = {
        file: info.file.originFileObj,
        file_type: props.fileType,
      };

      console.log({ payload });

      props.api
        .apiImageFileUploader(reqdata)
        .then((res) => {
          console.log({ res });

          let attachmentId = res?.data?.attachment_id;
          let url = res?.data?.url;
          setFilevalue(attachmentId);
          setImageUrl(url);
          setFileList([
            {
              uid: attachmentId,
              name: info.file.name,
              status: "done",
              imageUrl: imageUrl,
              url: `${
                props.apis().api_base
              }/api/v1/files/download/${attachmentId}`,
            },
          ]);
          inputRef.current.invalue = attachmentId;
          // props.onFileUploaded && props.onFileUploaded(attachmentId);
          props.onChange && props.onChange(info.file);
          swal2.closeModal();
        })
        .catch((error) => {
          swal2.closeModal();
          swal2.fire({
            title: error?.message,
            timer: 3000,
          });
          console.log(error);
        });
    }

    if (info.file.status === "removed") {
      console.log({ filevalue });

      const handleRemove = () => {
        swal2.closeModal();
        inputRef.current.invalue = "";
        setFilename("");
        setFilevalue("");
        setFileList([]);
        props.onChange && props.onChange("");
      };
      props.api
        .apiImageFileDelete({ attachment_id: filevalue })
        .then(handleRemove)
        .catch(handleRemove);
    }
  };
  const onPreview = async (file) => {
    console.log({ file, filevalue });
    if (filevalue) {
      swal2.fire({
        title: `${file?.name}`,
        imageUrl: `data:image/png;base64,${filevalue}`,
        imageWidth: 400,
        imageHeight: 200,
        imageAlt: "Custom image",
        confirmButtonText: "Close",
      });
    }
  };
  let { multiple = false, maxCount = 1 } = props;

  const attrs = {
    ...Object.except(props, ["className", "name", "onChange"]),
    onChange: handleChange,
    ...(props?.showPreview
      ? {
          onPreview: onPreview,
        }
      : {}),
    multiple,
    maxCount,
  };

  return (
    <Upload
      {...attrs}
      customRequest={({ onSuccess }) => onSuccess("ok")}
      fileList={fileList}
    >
      <input
        ref={inputRef}
        type="hidden"
        className="filevalue_container"
        name={props.name}
        {...(props.accept ? { accept: props.accept } : {})}
        filename={filename}
        imageurl={imageUrl}
        value={filevalue}
      ></input>
      <Button icon={<UploadOutlined />}>{props?.fileLabel || "Upload"}</Button>
      {/* <div className="text-end ml-auto">(jpg, doc, pdf)</div> */}
    </Upload>
  );
};
export default connect(AppUploadFile);
