import React, { Component } from "react";

export default class AppOrderLists extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orderLists: this.props.orderLists,
    };
    this.refs = {
      closebtn: React.createRef(),
    };
    this.attrs = Object.except(props, [
      "title",
      "className",
      "role",
      "closeIcon",
      "contentClass",
    ]);
  }

  componentDidMount() {
    const { onLoad = () => "" } = this.props;
    onLoad(this.refs.closebtn);
  }

  render() {
    let {
      className = "",
      closeIcon = "fa fa-close",
      contentClass = "",
      onClose = () => "",
      alert = "",
      title = "",
      modaltype = "",
    } = this.props;

    return (
      <>
        <div
          className={"modal custom-modal fade " + className}
          role="dialog"
          data-bs-backdrop="static"
          {...this.attrs}
        >
          <div
            className="modal-dialog modal-dialog-centered modal-xl"
            role="document"
          >
            <div
              className={`modal-content ${
                modaltype !== "info" ? "alert_modal_content" : ""
              }`}
            >
              <div className="modal-header">
                <h5 className="modal-title">Order List</h5>
                <button
                  type="button"
                  ref="closebtn"
                  className="close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  id="bsmodalclose"
                  onClick={onClose}
                >
                  <span aria-hidden="true">
                    <i className={closeIcon}></i>
                  </span>
                </button>
              </div>
              <div className="modal-body">
                <div className="row g-3 mx-auto py-4">
                  <div className="col-12 col-lg-6">{this.props.children}</div>
                  <div className="summary_container col-12 col-lg-6">
                    <div className="title text-center mb-3">
                      <p className="bill_title m-0">Bill Summary</p>
                      {/* <img src={imagepaths.seperatorIcon} alt="seperatorIcon" /> */}
                    </div>
                    <div className="summary_table">
                      <table className="table m-0">
                        <tbody>
                          <tr>
                            <td>Subtotal</td>
                            <td className="red">$100.00</td>
                          </tr>
                          <tr className="gst_total">
                            <td>GST and restaurant charges</td>
                            <td>$ 5.90</td>
                          </tr>
                          <tr>
                            <td>Grand Total</td>
                            <td className="red">$105.90</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div className="form-group text-center mt-5">
                  <button
                    type="button"
                    ref="closebtn"
                    className="btn me-3 btn-rounded btn_success"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    onClick={onClose}
                  >
                    Accept
                  </button>
                  <button
                    type="button"
                    ref="closebtn"
                    className="btn btn-primary btn-rounded btn_close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    onClick={onClose}
                  >
                    Reject
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
