import React, { Component } from "react";
import { Link } from "react-router-dom";
import mixinsFunc from "./mixins";

export class MenulistNavbar extends Component {
  constructor(props) {
    super(props);
    this.mixins = mixinsFunc(this);
    this.state = {
      searchTerm: "",
    };
  }
  setSearchTerm(val) {
    console.log("input val: ", val);
    this.setState({
      searchTerm: val,
    });
    let fItems = this.filterFoodItems(this.mixins.appetizerData);
    console.log("fItems: ", fItems);
  }

  filterFoodItems(foodArr) {
    console.log("searchTerm state: ", this.state.searchTerm);
    if (this.state.searchTerm === "") {
      return foodArr;
    }
    const filteredFoodItems = foodArr.filter((item) =>
      item.title.toLowerCase().includes(this.state.searchTerm.toLowerCase())
    );
    return filteredFoodItems;
  }

  getBackLink() {
    if (this.props.urlparams.location) {
      let backLink = `/client/menulist?location=${this.props.urlparams.location}&table_number=${this.props.urlparams.table_number}`;
      return backLink;
    } else {
      return "/client/menulist";
    }
  }
  render() {
    let { back, title } = this.props;
    let { searchTerm } = this.state;
    return (
      <>
        <div className="menulist_navbar">
          {back && (
            <Link to={back}>
              <button type="button" className="menu_back_btn">
                <i className="fa fa-angle-left"></i>
              </button>
            </Link>
          )}
          <Link to={this.getBackLink()}>
            <img
              src={imagepaths.headerlogo}
              alt="headerlogo"
              className="img-fluid"
            />
          </Link>
          {title ? (
            <div className="menu_title_container mt-4">
              <h4>{title}</h4>
              <img
                src={imagepaths.seperatorIcon}
                alt="seperatorIcon"
                className="m-0"
              />
            </div>
          ) : (
            <div className="search_filter_container">
              <i className="fa fa-search search"></i>
              <input
                type="text"
                className="menu_filter_input"
                placeholder="Type dish name"
                value={searchTerm}
                onChange={(e) => this.setSearchTerm(e.target.value)}
              />
              <i className="fa fa-microphone mic"></i>
            </div>
          )}
        </div>
      </>
    );
  }
}

export default connect(MenulistNavbar);
