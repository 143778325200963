/*eslint no-extend-native: ["error", { "exceptions": ["Array"] }]*/
Array.prototype.sum = function (ini = 0) {
    return this.reduce((a, b) => (Number(a) + Number(b)), ini).toFixed(2).parse();
}

Array.prototype.col = function (col, value) {
    return this.find(o => o[col] === value);
}

Array.prototype.obj = function (col = "name", val = "value") {
    return this.reduce((o, n) => ({ ...o, [n[col]]: n[val] }), {});
}

Array.prototype.maxObj = function () {
    if (this.length) {
        let initObj = this[0];
        let len = Object.keys(initObj);
        for (const key in this) {
            let newlen = Object.keys(this[key]).length;
            if (len < newlen) {
                len = newlen;
                initObj = this[key];
            }
        }
        return initObj;
    }
}

Array.prototype.maxObjKeys = function () {
    return Object.keys(this.maxObj() || {});
}

Array.prototype.csv = function () {
    let csv = "";
    let headers = this.maxObjKeys().map(o => `"${o}"`).join() + "\n"
    let data = this.map(o => {
        return Object.values(o).map(o => `"${o}"`).join();
    }).join("\n");
    csv = headers + data;
    return csv;
}

Array.prototype.unique = function () {
    return Array.from((new Set(this)));
}

Array.prototype.chunk = function (perChunk) {
    return this.reduce((resultArray, item, index) => {
        const chunkIndex = Math.floor(index / perChunk)
        if (!resultArray[chunkIndex]) {
            resultArray[chunkIndex] = []
        }
        resultArray[chunkIndex].push(item)
        return resultArray
    }, []);
}

Array.prototype.filllen = function (len, val = 0) {
    let arr = [];
    arr.length = len;
    len && arr.fill(val);
    return (len && arr) || [];
}

Array.prototype.transpose = function() {
    if (this.length == 0) return this;
    return this[0].map((col, i) => this.map(row => row[i]));
}

Array.prototype.chunkSwap = function() {
    if (this.length == 0) return this;
    let parentsize = this.length;
    return this.chunkjoin().chunk(parentsize);
}

Array.prototype.transposeSwap = function() {
    if (this.length == 0) return this;
    return this.chunkSwap().transpose();
}

Array.prototype.chunkjoin = function() {
    return this.reduce((o,n)=>{
        return o.concat(n);
    },[]);
}

Array.prototype.exclude = function(excludes) {
    return this.filter(o=>!excludes.includes(o));
}

Array.prototype.only = function(includes,name) {
    return this.filter(o=>includes.includes((name?o[name]:o)));
}

Array.prototype.options = function(name="",label="",mapfun=(v)=>v.toTitleCase()) {
    return this.map(v=>{
        if(v instanceof Object) {
            return {...v,key:v[name],label:(v[label]||v[name])}
        } else {
            return {key:v,label:mapfun(v)}
        }
    });
}