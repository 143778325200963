import React, { Component } from "react";
import MenulistNavbar from "../MenulistNavbar";
import { OrderList } from "../../admin/dine_in_orders/dine_in_orders";

export class foodcart extends Component {
  constructor(props) {
    super(props);
    props.initialconstruct.bind(this)("foodcart");
    this.state = {
      orderLists: [
        { dish: "Mutton Biryani", price: 25.5, quantity: "1" },
        { dish: "Chicken Biryani", price: 20, quantity: "2" },
        { dish: "Fish Biryani", price: 34.5, quantity: "1" },
        { dish: "Paneer Biryani", price: 20, quantity: "1" },
      ],
    };
  }
  get subcategory() {
    return this.props.urlparams.subcategory;
  }
  placeOrderHandler() {
    console.log("place order");
    let orderSuccessModal = new Modal(
      document.getElementById("orderSuccessModal")
    );
    orderSuccessModal.show();
  }
  getBackLink() {
    if (this.props.urlparams.location) {
      let backLink = `/client/addmenuitems?` + Object.QueryString({
        ...this.props.urlparams,
      });
      return backLink;
    } else {
      return "/client/menulist";
    }
  }
  render() {
    return (
      <>
        <MenulistNavbar back={this.getBackLink()} title="Items Added" />
        <div className="menu_content">
          <div className="row g-3 mx-auto py-4 cart_list_box_row">
            <div className="col-12 col-lg-6">
              <div className="cart_list_box pt-5">
                {this.state.orderLists?.map((list) => (
                  <CartList
                    title={list.dish}
                    price={list.price}
                    quantity={list.quantity}
                  />
                ))}
              </div>
            </div>
            <div className="col-12 col-lg-6">
              <div className="menu_title_container mt-4 text-center">
                <h4>Bill Summary</h4>
                <img
                  src={imagepaths.seperatorIcon}
                  alt="seperatorIcon"
                  className="title_img"
                />
              </div>
              <div className="summary_container mobile">
                <div className="summary_table">
                  <table className="table m-0">
                    <tbody>
                      <tr>
                        <td>Subtotal</td>
                        <td className="red">$100.00</td>
                      </tr>
                      <tr className="gst_total">
                        <td>GST and restaurant charges</td>
                        <td>$ 5.90</td>
                      </tr>
                      <tr>
                        <td>Grand Total</td>
                        <td className="red">$105.90</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div className="bottom_cart_card">
            <div className="">
              <p>Location : Allentown</p>
            </div>
            <button
              type="button"
              className="view_cart_btn"
              onClick={() => this.placeOrderHandler()}
            >
              Place Order
            </button>
          </div>
        </div>
        <AppAlertModal
          alert="success"
          id="orderSuccessModal"
          message=""
          line1="Your order has been placed successfully!"
          line2="Will confirm order status soon....."
        />
      </>
    );
  }
}

export default connect(foodcart);

export const CartList = ({ title, price, quantity }) => (
  <div className="order_list_card">
    <div className="row mx-auto px-0 g-3">
      <div className="col-12 col-lg-6">
        <div className="d-flex align-items-start">
          <img src={imagepaths.listIcon} alt="listIcon" />
          <div className="ms-3">
            <p className="dish_name mb-2">{title}</p>
            <p className="price m-0 fw-bold">{`$ ${price.toFixed(2)}`}</p>
          </div>
          <div className="addcart_btn_container">
            <button type="button" className="reduce">
              -
            </button>
            <p className="qty m-0">1</p>
            <button type="button" className="add">
              +
            </button>
          </div>
        </div>
      </div>
      <div className="col-12 col-lg-6 d-flex justify-content-around justify-content-lg-between"></div>
    </div>
  </div>
);
