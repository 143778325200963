export default [
    {
        title: "Dashboard",
        itemId: "/admin/dashboard",
        icon: "la la-user",
        iconStyle: imagepaths.Dashboard,
        iconActive: imagepaths.DashboardActive,
    },
    // {
    //     title: "Dine in Orders",
    //     itemId: "/admin/dine_in_orders",
    //     icon: "la la-user",
    // },
    {
        title: "Party Orders",
        itemId: "/admin/party_orders",
        icon: "la la-user",
        iconStyle: imagepaths.checkListIcon,
        iconActive: imagepaths.checkListIconActive,
    },
    // {
    //     title: "Banquet Hall Bookings",
    //     itemId: "/admin/banquet_hall",
    //     icon: "la la-user",
    // },
    // {
    //     title: "Party Orders",
    //     itemId: "/admin/party_orders",
    //     icon: "la la-user",
    // },
    // {
    //     title: "Table Reservations",
    //     itemId: "/admin/table_reservations",
    //     icon: "la la-user",
    // },
    {
        title: "Menu Management",
        itemId: "/admin/menu_management",
        icon: "la la-user",
        iconStyle: imagepaths.fileEditIcon,
        iconActive: imagepaths.fileEditIconActive,
    },
    {
        title: "Employee Management",
        itemId: "/admin/employee_management",
        icon: "la la-user",
        iconStyle: imagepaths.personsIcon,
        iconActive: imagepaths.personsIconActive,
    },
    {
        title: "QR Code Generate",
        itemId: "/admin/qr_generate",
        icon: "la la-user",
        iconStyle: imagepaths.qrIcon,
        iconActive: imagepaths.qrIconActive,
    },
    // {
    //     title: "Add Menu Items",
    //     itemId: "/client/menulist",
    //     icon: "la la-user",
    // },
    // {
    //     title: "Payoff History",
    //     itemId: "/admin/payoff_history",
    //     icon: "la la-user",
    //     subNav: [
    //         {
    //             title: "link 1",
    //             icon: "",
    //             icon: "la la-user",
    //         },
    //         {
    //             title: "link 2",
    //             icon: "",
    //             icon: "la la-user",
    //         }
    //     ]
    // },
]