import React, { Component } from "react";

export class Logout extends Component {
  constructor(props) {
    super(props);
    props.initialconstruct.bind(this)("Logout");
  }

  componentDidMount() {
    this.api.apiLogout();
  }

  render() {
    return (
      <AppUngardWrapper title="Login">
        <div className="account-box">Loging out please wait</div>
      </AppUngardWrapper>
    );
  }
}

export default connect(Logout);
