export const commonService = {
    api() {
        return window.api;
    },
    apiSuccess(resolve = ((v) => (v)), id = 0, responce = {}) {
        return resolve(responce.data);
    },
    apiFailure(reject, id, error) {
        let app = window?.app;
        if ([400, 404, 500].includes(error?.response?.status)) {
            let message = (
                error.response.data.issues.error ||
                error.response?.issues?.error ||
                error.response?.issues?.message ||
                error.response?.data?.issues?.message ||
                error.response?.data?.message ||
                error.response?.data?.msg
            )
            alert(message);
        }
        else if (error?.response?.status == 401) {
            console.log("UNAUTHORIZED");
            const resolveApiFailure = () => {
                app.props.setstore("auth", false);
                app.props.setstore("user", false);
                localStorage.clear();
                navigate("login");
            };

            let refreshTokenAttemp = localStorage.getItem("refreshTokenAttemp");
            refreshTokenAttemp = Number(refreshTokenAttemp) || 0;
            console.log({ refreshTokenAttemp });
            if (Number(refreshTokenAttemp) <= 1) {
                mixins.debounce(() => {
                    localStorage.setItem("refreshTokenAttemp", +Number(refreshTokenAttemp) + 1);
                    console.log({ refreshTokenAttemp });
                    app.props.api
                        .apiRefreshToken()
                        .then(() => {
                            window.location.reload();
                        }
                        )
                        .catch(() => {
                            resolveApiFailure();
                        });
                });
            } else {
                resolveApiFailure();
            }
        }
        let globalLoader = document.getElementById("globalLoader");
        globalLoader?.classList.add("d-none");
        console.log({ error }, error?.response?.status);
        reject(error);
    },
    endpoint2url(endpoint) {
        let api = window.api;
        return endpoint.startsWith("http") ? endpoint : api.api_base + endpoint;
    },
    request(endobj = "", post = {}, header = {}) {
        let endpoint = endobj.url;
        let objmethod = endobj.method;
        let data = post;
        let axios = window.axios;
        let api = window.api;
        let globalLoader = document.getElementById("globalLoader");

        let inHeaders = {
            headers: {
                authorization: "Bearer " + window.store.getState().root.auth,
                ...header
            },
        };
        let bundel = {
            payload: {},
            urltransform: (data = post) => {
                let func = endobj.url;
                if (func instanceof Function) {
                    endpoint = func(data);
                } else {
                    endpoint = Object.entries(data).reduce((url, [key, val]) => {
                        return url.split(`{${key}}`).join(val);
                    }, endobj.url);
                }
                return bundel;
            },
            multipart: () => {
                inHeaders.headers = {
                    ...(inHeaders.headers || {}),
                    "Content-Type": "multipart/form-data"
                };
                return bundel;
            },
            config: (reqconfig) => {
                let temp = reqconfig instanceof Function ? reqconfig(inHeaders) : reqconfig;
                inHeaders = {
                    ...inHeaders,
                    ...temp
                }
                return bundel;
            },
            headers: (reqconfig) => {
                let temp = reqconfig instanceof Function ? reqconfig(inHeaders.headers) : reqconfig;
                inHeaders.headers = {
                    ...temp
                }
                return bundel;
            },
            transform(callback = ((v) => (v))) {
                data = callback(post);
                return bundel;
            },
            exec: (message = false, method = "get") => {
                globalLoader?.classList.remove("d-none");
                bundel.results = new Promise((resolve, reject) => {
                    let app = window?.app;
                    let id = Math.random().toString().slice(2);
                    app.mixin.pushRequest({ message, id, type: "loader" });
                    method = objmethod || method || (data instanceof FormData ? "post" : "get");
                    let url = endpoint.startsWith("http") ? endpoint : api.api_base + endpoint;
                    let isGet = method == "get";
                    let isDelete = method == "delete";
                    url = isGet ? url + Object.encodeuri(data) : url;
                    let requestarr = isGet ? ({ url, inHeaders }) : ({ url, data, inHeaders });
                    requestarr = isDelete ? ({
                        url, inHeaders: {
                            ...inHeaders,
                            data
                        }
                    }) : requestarr;
                    bundel.payload.messageId = id;
                    axios[method](...Object.values(requestarr))
                        .then((...arg) => this.apiSuccess(resolve, id, ...arg))
                        .catch((...arg) => this.apiFailure(reject, id, ...arg));
                });
                return bundel;
            },
            log() {
                console.log({ endobj, post, data, Headers: inHeaders, responce: bundel.results });
                return bundel;
            },
            mapresults(callback = ((v) => (v))) {
                const execpromise = bundel.results;
                const newpromise = new Promise((resolve, reject) => {
                    execpromise.then(async (data) => {
                        let resultdata = callback(data);
                        if (resultdata instanceof Promise) {
                            resultdata = await resultdata;
                        }
                        resolve(resultdata)
                    }).catch(reject);
                });
                bundel.results = newpromise;
                return bundel;
            },
            get() {
                let app = window?.app;
                const execpromise = bundel.results;
                const newpromise = new Promise((resolve, reject) => {
                    execpromise.then(async (data) => {
                        resolve(data)
                        globalLoader?.classList.add("d-none");
                    }).catch(reject).then(() => {
                        app.mixin.popRequest(this.payload.messageId);
                    });
                });
                return newpromise;
            }
        };
        return bundel;
    },
    mokeapi(callback = (() => ('')), message = "", delay = 1000) {
        return new Promise((resolve, reject) => {
            let app = window?.app;
            let id = Math.random().toString().slice(2);
            message && app.mixin.pushRequest({ message, id });
            setTimeout(() => {
                resolve(callback());
                message && app.mixin.popRequest(id);
            }, delay);
        });
    }
}

export default commonService;