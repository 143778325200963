import React, { Component } from "react";

export class SpecialItems extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.specialItemsData = [
      {
        id: 1,
        img: imagepaths.specialItemIcon,
        title: "Chicken Special",
        content:
          "The idea of Biryani City came into conception when we decided to share our love for food with the..",
      },
      {
        id: 2,
        img: imagepaths.specialItemIcon,
        title: "Egg Special",
        content:
          "The idea of Biryani City came into conception when we decided to share our love for food with the..",
      },
    ];
  }
  render() {
    return (
      <>
        <div className="menu_title_box" style={{ background: "transparent" }}>
          <h2>Special Items</h2>
          <img src={imagepaths.seperatorIcon} alt="seperatorIcon" />
        </div>
        <div className="row g-4 mx-auto p-3">
          {this.specialItemsData.map((item, i) => {
            return (
              <SpecialItemCard
                img={item.img}
                title={item.title}
                content={item.content}
                key={i}
                id={item.id}
              />
            );
          })}
        </div>
      </>
    );
  }
}

export default SpecialItems;

export class SpecialItemCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      favIcon: false,
    };
  }
  favIconClick() {
    this.setState({
      favIcon: !this.state.favIcon,
    });
  }
  render() {
    let { img, title, content } = this.props;
    let { favIcon } = this.state;
    return (
      <div className="col-sm-6 col-12">
        <div className="card special_item_card">
          <button
            type="button"
            className="fav_btn"
            onClick={() => this.favIconClick()}
          >
            <i className={favIcon ? "fa fa-heart" : "fa fa-heart-o"}></i>
          </button>
          <img src={img} className="card-img-top" alt="" />
          <div className="card-body">
            <h5 className="card-title">{title}</h5>
            <p className="card-text">{content}</p>
          </div>
          <div className="card-footer">
            <p className="offer">
              <span></span>
              20% OFF
            </p>
            <div className="rating">
              <p>3.7</p>
              <i className="fa fa-star"></i>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
