const files = require.context('./', true, /\.jsx$/i);
const file_keys = files.keys()
    .filter(key => key !== "./index.js")
    .filter(key => {
        return (key.startsWith('./App'));
    });
const partialpages = [
    ...file_keys.map(key => ({ path: "/" + key.pathpluck(), Element: files(key).default, name: key.filename() }))
]
partialpages.map(component => {
    window[component.name] = component.Element;
});
// console.log({partialpages});
if (window.Appl) {
    Object.entries(window.Appl).map(([key, val]) => {
        window[key] = val;
    })
}


export default partialpages;
