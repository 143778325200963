import { QRCodeCanvas } from "qrcode.react";
import React, { Component } from "react";
import { jsPDF } from "jspdf";
export class QrGenerate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      qrValue: "",
      submitBtnVal: false,
    };
    this.refs = {
      qrForm: React.createRef(),
    };
    this.baseURL =
      "http://biryanicity_fe.impetusaisolutions.com/client/menulist";
  }
  qrFormHandler(e) {
    let targetValue = e.target.value;
    let targetName = e.target.name;
    console.log(targetName, targetValue);
    if (targetName.length > 0) {
      this.setState({
        submitBtnVal: true,
      });
    } else {
      this.setState({
        submitBtnVal: false,
      });
    }
  }

  downloadQR() {
    const canvas = document.getElementById("qrCodeImage");
    const pngUrl = canvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    let downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = "qrCodeImage.png";
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  }

  generateQR(e, d, form) {
    // console.log({ d, form });
    let data = this.refs.qrForm.form.current.getData();
    // console.log("data", data);
    let { location, table_number } = data;
    let qrURL = `${this.baseURL}?location=${location}&table_number=${table_number}`;
    if (d.location.length > 0) {
      console.log(qrURL);
      this.setState({
        qrValue: qrURL,
      });
    }
    // let qrURL;

    // d.location = d.location.toLowerCase();

    // if (d.location === "allentown") {
    //   qrURL = `https://biryanicity.com/menulist-allentown.html`;
    // } else if (d.location === "norristown") {
    //   qrURL = `https://biryanicity.com/menulist-norristown.html`;
    // } else {
    //   qrURL = `https://biryanicity.com/menulist-hillsborough.html`;
    // }
  }

  generatePDF = () => {
    // Defines the pdf
    let pdf = new jsPDF({
      orientation: "landscape",
      unit: "mm",
      format: [40, 40],
    });

    // Transforms the canvas into a base64 image
    let base64Image = document.getElementById("qrCodeImage").toDataURL();

    // Adds the image to the pdf
    pdf.addImage(base64Image, "png", 0, 0, 40, 40);

    // Downloads the pdf
    pdf.save("QR.pdf");
  };
  render() {
    let { qrValue, submitBtnVal } = this.state;
    return (
      <AppWrapper title="QR Generate">
        <AppForm
          ref="qrForm"
          id="qrForm"
          className="qr_form mb-4"
          onSubmit={(...args) => this.generateQR(...args)}
        >
          <AppInput
            name="location"
            label="Location"
            type="text"
            required="true"
            // onChange={(e) => this.qrFormHandler(e)}
          />
          <AppInput
            name="table_number"
            label="Table Number"
            type="number"
            required="true"
            // onChange={(e) => this.qrFormHandler(e)}
          />
          <div className="row d-flex text-center g-3 mx-auto mt-3">
            <div>
              <button
                className="btn modal_btn lg generate_qr_btn"
                //   disabled={!submitBtnVal}
              >
                Generate QR Code
              </button>
            </div>
          </div>
        </AppForm>
        {qrValue && (
          <>
            <div className="qr_code_container">
              <QRCodeCanvas
                id="qrCodeImage"
                value={qrValue}
                size={250}
                level="L"
              />
              <a onClick={() => this.downloadQR()}> Download QR </a>
            </div>
            {/* <button
              className="btn btn-primary"
              onClick={() => this.generatePDF()}
            >
              Download pdf
            </button> */}
          </>
        )}
      </AppWrapper>
    );
  }
}

export default QrGenerate;
