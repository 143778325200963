export default {
    // apiPostCompany(data, urldata = {}) {
    //     return this.request(this.api().postCompany, data)
    //         .urltransform({
    //             ...urldata,
    //             ...mixins.params()
    //         })
    //         .exec().log().get();
    // },
    // apiGetCompany(data, urldata = {}) {
    //     return this.request(this.api().getCompany, data)
    //         .urltransform({
    //             ...urldata,
    //             ...mixins.params()
    //         })
    //         .exec().log().get();
    // }
}