import React from "react";
import { useRef, useEffect } from "react";

function AppAccordian({ data = [], id = "accordionExample" }) {
  const accordian = useRef();
  useEffect(() => {
    accordian?.current
      ?.querySelector(".accordion-item>.accordion-header>button")
      ?.click();
  }, [data]);

  return (
    <div ref={accordian} className="accordion custom_accordion" id={id}>
      {data.map(
        (
          {
            Content = () => <div>Accordian Content</div>,
            Title = () => (
              <button className="btn btn-link" type="button">
                Collapsible Group Item #1
              </button>
            ),
            TitleEndText = () => <></>,
          },
          i
        ) => (
          <div className="accordion-item" key={i}>
            <h2 className="accordion-header" id={"heading" + i}>
              <button
                className="accordion-button collapsed text-base"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target={"#collapse" + i}
                aria-expanded="true"
                aria-controls={"collapse" + i}
              >
                <Title />
                <span className="ms-auto my-0">
                  <TitleEndText />
                </span>
              </button>
            </h2>
            <div
              id={"collapse" + i}
              className="accordion-collapse collapse"
              aria-labelledby={"heading" + i}
              data-bs-parent={"#" + id}
            >
              <div className="accordion-body px-lg-5">
                <Content />
              </div>
            </div>
          </div>
        )
      )}
    </div>
  );
}

export default AppAccordian;
